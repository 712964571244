import React, { useState, useEffect, useRef, useContext } from "react";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from 'primereact/inputswitch';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import FieldDialog from "./fieldDialog"
import AIDialog from "./aiDialog";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {appContext} from '../../../App'
import { getPathByName } from "../../../routesUtils"
import RevisionDialog from "./revisionDialog";

function FormEdit(props) {
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [currentField, setCurrentField] = useState(undefined);
  const [aiDialogVisible, setAiDialogVisible] = useState(false);
  const [currentRevision, setCurrentRevision] = useState(false);
  let { formId } = useParams(null);
  let { portalId } = useParams(null);
  const menuFieldsTop = useRef(null);
  const menuFieldsBottom = useRef(null);
  const AppContext = useContext(appContext)

  useEffect(() => {
    if (props.type != 'template') {
      if(props?.mode && props.mode == 'create'){
        let s = {
          name : 'Nouveau formulaire',
          fields: [],
          enabled: false
        }
        setForm(s);
      }
      else{
        let params = [
          { key: "join", value: "form_revisions" },
          { key: "include", value: "forms.*,form_revisions.id,form_revisions.created_at,form_revisions.is_valid" },
        ]
        AppContext.api.getRecord("forms", [formId], params).then((res) => {
          setForm(res.data);
        }).catch(function (error) {
          AppContext.showNotification({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger le formulaire', life: 3000 });
        });
      }
    }
    else{
      if(props?.mode && props.mode == 'create'){
        let s = {
          name : 'Nouveau formulaire',
          fields: [],
          enabled: false
        }
        setForm(s);
      }
      else{
        let params = [
          { key: "join", value: "form_revisions" },
          { key: "include", value: "forms.*,form_revisions.id,form_revisions.created_at,form_revisions.is_valid" },
        ]
        AppContext.api.getRecord("forms", [formId], params).then((res) => {
          setForm(res.data);
        }).catch(function (error) {
          AppContext.showNotification({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger le formulaire', life: 3000 });
        });
      }
    }

  }, []);

  const addField = (event) => {

    let field = {
      label: 'Nouveau champ',
      type: event.item.name,
      uid: uuidv4(),
    }

    if (event.item?.additionalFields) {
      field = { ...field, ...event.item.additionalFields }
    }

    if (event.item.position == 'start') {
      var array = [field, ...form.fields];
    }
    else {
      var array = [...form.fields, field];
    }
    setForm({ ...form, fields: array });
    editField(field)
  }


  const fieldsTypeStart = [
    {
      label: 'Séparateur de section',
      name: 'section',
      position: 'start',
      command: addField
    },
    {
      label: 'Champ texte',
      name: 'inputtext',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        placeholder: "",
        isVisibleCondition: false

      }
    },
    {
      label: 'Champ numérique',
      name: 'inputnumber',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        isVisibleCondition: false

      }
    },
    {
      label: 'Email',
      name: 'inputemail',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        isVisibleCondition: false

      }
    },
    {
      label: 'Date',
      name: 'inputdate',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        isVisibleCondition: false

      }
    },
    {
      label: 'Zone de texte',
      name: 'textarea',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        isVisibleCondition: false

      }
    },
    {
      label: 'Selection',
      name: 'select',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        options: [],
        isVisibleCondition: false
      }
    },
    {
      label: 'Bouton radios',
      name: 'radio',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        options: [],
        isVisibleCondition: false
      }
    },
    {
      label: 'Cases à cocher',
      name: 'checkbox',
      position: 'start',
      command: addField,
      additionalFields: {
        required: false,
        note: '',
        options: [],
        isVisibleCondition: false
      }
    }
  ];

  let fieldsTypeEnd = fieldsTypeStart.map((item) => {
    let it = { ...item }
    it.position = 'end'
    return it;
  })

  const startContent = <h2 className="m-0">Formulaire</h2>;

  const downloadObjectAsJson = (exportObj, exportName) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  const downloadFS = () => {
    AppContext.api.get("/formsolution/sketch/"+formId).then((res) => {
      downloadObjectAsJson(res.data,'_sketch')
    })
  }

  const endContent = (
    <React.Fragment>
      <span className="p-buttonset">
        <Button label="Sauvegarder" icon="pi pi-check" onClick={() => saveForm()} severity="success" ></Button>
        <Button label="Générer avec I.A." icon="pi pi-wrench" outlined onClick={() => setAiDialogVisible(true)}></Button>
        <Button label="Maquette FormSolution" icon="pi pi-wrench" outlined onClick={() => downloadFS()}></Button>
      </span>
    </React.Fragment>
  );

  const saveForm = () => {
    let errors = {}
    if(form.name == '') errors['identifier'] = 'Champs "Nom" requis.'
    
    if (Object.keys(errors).length > 0) {
      let msg = [];
      for (const name in errors) msg.push(errors[name]) 
      AppContext.showNotification({
        severity: 'error',
        summary: 'Erreurs',
        detail: msg.map((m, i) => <div key={i}>{m}</div>),
        life: 3000
      });
      return;
    }

    if(form?.id && form.id){
      AppContext.api.putRecords("forms", [formId], form).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Formulaire sauvegardé', life: 3000 });
      })
    }
    else{
      AppContext.api.postRecords("forms", form).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Formulaire sauvegardé', life: 3000 });
        setForm({ ...form, id: res.data });
        if(props.type == 'template'){
          navigate(getPathByName('configEditForm', { formId: res.data }))
        }
        else{
          navigate(getPathByName('portalEditForm', { portalId: portalId, formId: res.data }))
        }
      })
    }
  }

  const setFields = (fields) => {
    if (form && form?.fields) {
      setForm({ ...form, fields: fields });
    }
  }

  const setName = (name) => {
    setForm({ ...form, name: name });
  }

  const setEnabled = (formState) => {
    setForm({ ...form, enabled: formState });
  }

  const fieldLabelTemplate = (field) => {
    let el = fieldsTypeStart.find((e) => e.name == field.type)
    let type = (el) ? '- '+el.label : '- Aucun type reconnu'
    let condition = (field?.isVisibleCondition && field.isVisibleCondition) ? <span><br />- Condition de visibilité</span> : ''
    return <><strong>{field.label}</strong><br />{type}{condition}</>
  };

  const fieldTypeTemplate = (field) => {
    let el = fieldsTypeStart.find((e) => e.name == field.type)
    return (el) ? <>{el.label}</> : <>-</>
  };

  const fieldRequiredTemplate = (field) => {
    if(field.type == "section") return '-'
    return field.required ? <strong>Oui</strong> : <span>Non</span>
  };

  const editField = (field) => {
    setCurrentField(field)
  }

  const saveField = (field) => {
    var array = [...form.fields];
    var index = array.indexOf(currentField)
    if (index !== -1) {
      array[index] = field;
      setForm({ ...form, fields: array });
    }
  }

  const cancelField = () => {
    setCurrentField(undefined)
  }

  const confirmRemoveField = (event, field) => {

    const accept = () => {
      var array = [...form.fields];
      var index = array.indexOf(field)
      if (index !== -1) {
        array.splice(index, 1);
        setForm({ ...form, fields: array });
      }
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer ce champ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  const actionFieldsBtns = (field) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            text raised
            icon="pi pi-pencil"
            onClick={() => editField(field)}
            size="small"
            severity="info"
          ></Button>
          <Button
            text raised
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveField(event, field)}
            size="small"
            severity="danger"
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const insertFields = (fields) => {

    if (form && form?.fields) {
      setForm({ ...form, fields: fields });
    }
    setAiDialogVisible(false)
  }

  const actionRevisionsBtns = (revision) => {
    return (
      <React.Fragment>
        {!revision.is_valid && <Button
            text raised
            icon="pi pi-eye"
            onClick={() => setCurrentRevision(revision)}
            size="small"
            severity="info"
          ></Button>}
      </React.Fragment>
    );
  };

  const setRevision = (revision) => {
    setForm({ ...form, fields: revision.fields });

  } 

  const sortRevisions = (revisions) => {
    if(revisions)
      return revisions.sort(function(a,b){
        return new Date(b.created_at) - new Date(a.created_at);
      });
    return []
  }

  return (
    <React.Fragment>
      <FieldDialog field={currentField} fields={form.fields} onSave={saveField} onCancel={cancelField} />
      <AIDialog visible={aiDialogVisible} onGenerate={insertFields} onCancel={() => setAiDialogVisible(false)} />
      <RevisionDialog currentRevision={currentRevision} onLoadRevision={setRevision} onClose={() => setCurrentRevision(false)} revisions={sortRevisions(form.form_revisions)}/>
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <div className="flex flex-grow-1">
          <div className="flex-grow-1 flex flex-column">
            <div className="p-2 text-right">
              <Menu model={fieldsTypeStart} popup ref={menuFieldsTop} />
              <Button label="Ajouter un champ" text raised icon="pi pi-plus" className="mr-2" onClick={(event) => menuFieldsTop.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
            </div>
            <div className="flex-grow-1" style={{ height: 0 }}>
              <ConfirmPopup />
              {form && form?.fields &&
                <DataTable scrollable scrollHeight="flex" value={form.fields} reorderableRows onRowReorder={(e) => setFields(e.value)} >
                  <Column rowReorder style={{ width: '3rem' }} />
                  <Column field={"label"} header={"Champ"} body={fieldLabelTemplate} />
                  {/* <Column field={"type"} header={"Type"} body={fieldTypeTemplate} /> */}
                  <Column field={"required"} header={"Requis"} body={fieldRequiredTemplate} />
                  <Column header="" body={actionFieldsBtns} style={{ width: '85px' }}></Column>
                </DataTable>
              }
            </div>
            <div className="p-2 text-right">
              <Menu model={fieldsTypeEnd} popup ref={menuFieldsBottom} />
              <Button label="Ajouter un champ" text raised icon="pi pi-plus" className="mr-2" onClick={(event) => menuFieldsBottom.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
            </div>
          </div>
          <div style={{ width: "400px" }}>
            <TabView>
              <TabPanel header="Options">
                <div className="flex flex-column gap-3 mb-3 mt-2">
                  <label htmlFor="name">Nom</label>
                  <InputText
                    id="name"
                    type="text"
                    value={form.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-row gap-3 mb-3 mt-4">
                  <InputSwitch id="formEnabled" checked={form.enabled} onChange={(e) => setEnabled(e.value)} />
                  <label htmlFor="formEnabled">Activé/Désactivé</label>
                </div>
              </TabPanel>
              <TabPanel header="Révisions">
                  <DataTable value={form.form_revisions}>
                      <Column field={"created_at"} header={"Date"} body={(item) => moment(item.created_at).format('LLL')}  />
                      <Column header="" body={actionRevisionsBtns} style={{ width: '85px' }}></Column>
                    </DataTable>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default FormEdit;
