import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { appContext } from '../../../App'
import { getPathByName } from "../../../routesUtils"

const Login = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const AppContext = useContext(appContext)

    const handleLogin = () => {
        AppContext.api.post("login", {
            username: login,
            password: password
        }).then((res) => {
            navigate(getPathByName('home'))
        }).catch((error) => {
            return true;
        })
    };

    return (
        <div className="App flex align-items-center justify-content-center">
            <Card title='Connexion'>
                <div className="flex flex-column" style={{minWidth:"250px"}}>
                    <div className="flex flex-column mb-3 gap-3 mt-3">
                        <label htmlFor="email">Email</label>
                        <InputText
                            id="email"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            placeholder="Email" />
                    </div>
                    <div className="flex flex-column mb-3 gap-3 mt-1">
                        <label htmlFor="password">Mot de passe</label>
                        <Password
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Mot de passe" 
                            inputStyle={{ width: '100%' }} className="w-full"
                            feedback={false} />
                    </div>
                </div>
                <div className="text-center">
                <Button onClick={handleLogin}>Se connecter</Button>
                </div>
            </Card>
        </div>
    );
};

export default Login;