import React, { useState, useEffect, useRef, useContext } from "react";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from "primereact/dropdown";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import API from "../../../api";
import { appContext } from '../../../App'
import { getPathByName } from "../../../routesUtils"

function UserEdit(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  let { userId } = useParams(null);
  const AppContext = useContext(appContext)

  useEffect(() => {
    if (props?.mode && props.mode == 'create') {
      let s = {
        name: 'Nouvel utilisateur',
        email: '',
        enabled: false,
        user_type: 'user',
        password: '',
        api_key:'',
      }
      setUser(s);
    }
    else {
      let params = [
      ]
      AppContext.api.getRecord("users", [userId], params).then((res) => {
        setUser(res.data);
      });
    }

  }, []);

  const setProperty = (property, value) => {
    setUser({ ...user, [property]: value });
  }

  const endContent = (
    <React.Fragment>
      <span className="p-buttonset">
        <Button label="Sauvegarder" icon="pi pi-check" severity="success" onClick={() => saveUser()}></Button>
      </span>
    </React.Fragment>
  );

  const saveUser = () => {
    let errors = {}
    if (user.name == '') errors['name'] = 'Champs "Nom" requis.'
    if (user.email == '') errors['email'] = 'Champs "Email" requis.'
    if (user.user_type == '') errors['user_type'] = 'Champs "Type d\'utilisateur" requis.'

    if (Object.keys(errors).length > 0) {
      let msg = [];
      for (const name in errors) msg.push(errors[name])
      AppContext.showNotification({
        severity: 'error',
        summary: 'Erreurs',
        detail: msg.map((m, i) => <div key={i}>{m}</div>),
        life: 3000
      });
      return;
    }

    let userTemp = {...user}
    if(userTemp.user_type == 'user'){
      if(userTemp.password1 != ''){
        if(userTemp.password1 != userTemp.password2){
          AppContext.showNotification({ severity: 'error', summary: 'Erreur', detail: 'Le mot de passe et la vérification ne correspondent pas.', life: 3000 });
          return;
        }
        else{
          userTemp.password = userTemp.password1;
        }
      }
      else{
        delete userTemp.password
      }
      delete userTemp.password1;
      delete userTemp.password2;
      delete userTemp.api_key;
    }
    else if(userTemp.user_type == 'service'){
      if(userTemp.api_key == '') delete userTemp.api_key
    }
    
    if (user?.id && user.id) {
      AppContext.api.putRecords("users", [userId], userTemp).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Utilisateur sauvegardé', life: 3000 });
      })
    }
    else {
      AppContext.api.postRecords("users", userTemp).then((res) => {
        setUser({ ...user, id: res.data });
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Utilisateur sauvegardé', life: 3000 });
        navigate(getPathByName('configEditUser', { userId: res.data }))
      })
    }
  }

  return (
    <div className="flex flex-column">
      <div className="flex flex-grow-1">
        {user && <Card
          header={
            <Toolbar
              start={<h2 className="m-0">Utilisateur</h2>}
              end={endContent}
              className="shadow-none border-bottom-1 surface-border border-noround-bottom"
            />
          }
          className="flex-grow-1 shadow-none border-1 surface-border mr-2 fullHeightCard overflowYContentCard"
        >

          <div className="flex flex-column mb-3 gap-3 mt-3">
            <label htmlFor="name">Nom</label>
            <InputText
              id="name"
              value={user.name}
              onChange={(e) => setProperty('name', e.target.value)}
              placeholder="Nom" />
          </div>
          <div className="flex flex-column mb-3 gap-3 mt-3">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={user.email}
              onChange={(e) => setProperty('email', e.target.value)}
              placeholder="Email" />
          </div>
          <div className="flex flex-column mb-3 gap-3 mt-3">
            <label htmlFor="enabled">Activé</label>
            <InputSwitch
              id="enabled"
              checked={user.enabled}
              onChange={(e) => setProperty('enabled', e.value)}
            />
          </div>
          <div className="flex flex-column mb-3 gap-3 mt-3">
            <label htmlFor="user_type">Type de compte :</label>
            <Dropdown
              id="user_type"
              value={user.user_type}
              onChange={(e) => setProperty('user_type', e.value)}
              options={[{ id: 'user', name: 'Utilisateur' }, { id: 'service', name: 'Service' }]}
              optionLabel="name"
              optionValue="id"
              placeholder="Selectionner un type d'utilisateur"
              className="w-full" />
          </div>
          {user.user_type == 'user' && <>
            <div className="flex flex-column mb-3 gap-3 mt-3">
              <label htmlFor="password1">Mot de passe</label>
              <div>
                <Password id="password1"
                  toggleMask
                  value={user.password1}
                  onChange={(e) => setProperty('password1', e.target.value)}
                  feedback={true} inputStyle={{ width: '100%' }} className="w-full" />
              </div>
            </div>
            <div className="flex flex-column mb-3 gap-3 mt-3">
              <label htmlFor="password1">Confirmation du mot de passe</label>
              <div><Password id="password2"
                toggleMask
                value={user.password2}
                onChange={(e) => setProperty('password2', e.target.value)}
                feedback={false} inputStyle={{ width: '100%' }} className="w-full" />
              </div>
            </div></>}
          {user.user_type == 'service' && <div className="flex flex-column mb-3 gap-3 mt-3">
            <label htmlFor="api_key">Clé d'accès</label>
            <div><Password id="api_key"
              toggleMask
              value={user.api_key}
              onChange={(e) => setProperty('api_key', e.target.value)}
              feedback={false} inputStyle={{ width: '100%' }} className="w-full" />
            </div>
          </div>}
        </Card>}
      </div>
    </div>
  );
}

export default UserEdit;
