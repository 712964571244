import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from 'primereact/inputtextarea';
import { appContext } from '../../../App'
import moment from 'moment';

function RevisionDialog(props) {
  const [currentRevision, setCurrentRevision] = useState(undefined);
  const [isVisible, setVisible] = useState(false);
  const [currentContent, setCurrentContent] = useState('');
  const AppContext = useContext(appContext)

  useEffect(() => {
    if (props.currentRevision) {
      setCurrentRevision(props.currentRevision)
      setVisible(true)
    }
  }, [props.currentRevision]);

  useEffect(() => {
    let tableName = (props.type == 'template') ? "step_template_revisions" : "step_revisions"
    if(currentRevision && currentRevision?.id){
      AppContext.api.getRecord(tableName,[currentRevision.id])
      .then((response) => {
        if(response?.data)
          setCurrentContent(response?.data)
      })
    }
  }, [currentRevision]);

  const closeDialog = () => {
    props.onClose()
    setCurrentRevision(undefined)
    setVisible(false)
  }

  const loadRevision = () => {
    props.onLoadRevision(currentContent)
    setCurrentRevision(undefined)
    setVisible(false)
  }

  const footerContent = (
    <div>
      <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
      <Button label="Récupérer" icon="pi pi-check" onClick={loadRevision} />
    </div>
  );

  return (
    <React.Fragment>
      <Dialog header="Révisions" footer={footerContent} visible={isVisible} style={{ minWidth: '50vw', maxWidth: '85vw' }} onHide={closeDialog}>
        <div className="flex flex-row gap-3" style={{minHeight:'60vh'}}>
          <div className="flex-grow-1" style={{ width: '300px' }}>
            <DataTable value={props.revisions}
              selectionMode="single" selection={currentRevision}
              onSelectionChange={(e) => setCurrentRevision(e.value)}>
              <Column field={"created_at"} header={"Date"} body={(item) => moment(item.created_at).format('LLL')} />
            </DataTable>
            </div>
          <div style={{ width: '800px' }} className="flex-grow-1 overflow-y" >
            <div style={{ height:0 }} dangerouslySetInnerHTML={{__html: currentContent.content}} />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default RevisionDialog;
