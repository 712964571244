import React, { useState, useEffect, useRef, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from 'primereact/inputswitch';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { getPathByName } from "../../../routesUtils"
import { appContext } from '../../../App'

function CodeEdit(props) {
  const navigate = useNavigate();
  const [code, setCode] = useState([]);
  const [steps, setSteps] = useState([])
  const [codeStep, setCodeStep] = useState(null);
  const [selectedSteps, setSelectedSteps] = useState(null);
  let { portalId, codeId } = useParams(null);
  const AppContext = useContext(appContext)
  const [filters, setFilters] = useState({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  useEffect(() => {

    if (props?.mode && props.mode == 'create') {
      let s = {
        identifier: 'Nouveau code',
        accesskey: '',
        enabled: false
      }
      setCode(s);
    }
    else {
      let paramsCodes = [
        { key: 'join', value: 'code_step' },
        { key: 'include', value: 'codes.*,code_step.*' }
      ]

      AppContext.api.getRecord("codes", [codeId], paramsCodes).then((res) => {
        setCode(res.data);
        setCodeStep(res.data.code_step)
        setSelectedSteps(res.data.code_step.map((item) => ({ id: item.step_id })))
      })

    }
    let params = [
      { key: 'include', value: 'id,name,enabled' }
    ]
    AppContext.api.getRecords("steps", params).then((res) => {
      setSteps(res.data.records);
    })
  }, []);

  const endContent = (
    <React.Fragment>
      <span className="p-buttonset">
        <Button label="Sauvegarder" icon="pi pi-check" onClick={() => saveCode()} severity="success" ></Button>
      </span>
    </React.Fragment>
  );

  const syncStep = async (ccode) => {
    let stepOIds = ccode.code_step.map((item) => item.step_id)
    let stepNIds = selectedSteps.map((item) => item.id)

    const idsToDelete = stepOIds.filter(id => !stepNIds.includes(id));
    const idsToInsert = stepNIds.filter(id => !stepOIds.includes(id));

    if (idsToDelete.length > 0) {
      let codeToDelete = codeStep.filter((cs) => idsToDelete.includes(cs.step_id))
      let codeIdsToDelete = codeToDelete.map((cs) => cs.id)
      await AppContext.api.deleteRecords("code_step", codeIdsToDelete)
      // console.log('IDs à supprimer:', codeIdsToDelete);
    }

    if (idsToInsert.length > 0) {
      let itemsToInsert = idsToInsert.map((stepId) => { return { 'step_id': stepId, 'code_id': ccode.id } })
      await AppContext.api.postRecords("code_step", itemsToInsert)
      // console.log('Nouveaux IDs à insérer:', itemsToInsert);
    }
  }

  const saveCode = () => {
    let errors = {}
    if(code.identifier == '') errors['identifier'] = 'Champs "Identifiant" requis.'
    if(code.accesskey == '') errors['accesskey'] = 'Champs "Code d\'accès" requis.'

    if (Object.keys(errors).length > 0) {
      let msg = [];
      for (const name in errors) msg.push(errors[name]) 
      AppContext.showNotification({
        severity: 'error',
        summary: 'Erreurs',
        detail: msg.map((m, i) => <div key={i}>{m}</div>),
        life: 3000
      });
      return;
    }

    if (code?.id && code.id) {
      AppContext.api.putRecords("codes", [codeId], code).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Code sauvegardé', life: 3000 });
      });
      syncStep(code)
    }
    else {
      AppContext.api.postRecords("codes", code).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Code sauvegardé', life: 3000 });
        let ccode = { id: res.data, code_step: [] };
        setCode({ ...code, ...ccode });
        syncStep(ccode)
        navigate(getPathByName('portalEditCode', { portalId: portalId, codeId: res.data }))
      });
    }
  }


  const setProperty = (name, value) => {
    let v = (value === undefined) ? null : value;
    setCode({ ...code, [name]: v });
  }

  return (
    <React.Fragment>
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={<h2 className="m-0">Code d'accès</h2>}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <div className="flex flex-grow-1">
          <div style={{ width: "400px" }} className="border-right-1 surface-border">
            <TabView>
              <TabPanel header="Options">
                <div className="flex flex-column mb-3 gap-3">
                  <label htmlFor="identifier">Identifiant</label>
                  <InputText
                    id="identifier"
                    value={code.identifier}
                    onChange={(e) => setProperty('identifier', e.target.value)}
                    placeholder="Identifiant"
                    className="mr-2" />
                </div>
                <div className="flex flex-column mb-3 gap-3">
                  <label htmlFor="identifier">Code d'accès</label>
                  <InputText
                    id="accesskey"
                    value={code.accesskey}
                    onChange={(e) => setProperty('accesskey', e.target.value)}
                    placeholder="Code d'accès"
                    className="mr-2" />
                </div>
                <div className="flex flex-row gap-3 mb-3 mt-2">
                  <InputSwitch
                    id="codeEnabled"
                    checked={code.enabled}
                    onChange={(e) => setProperty('enabled', e.value)} />
                  <label htmlFor="codeEnabled">Activé/Désactivé</label>
                </div>
                <div className="flex flex-row gap-3 mb-3">
                  <InputSwitch
                    id="stepReferenced"
                    checked={code.global}
                    onChange={(e) => setProperty('global', e.value)} />
                  <label htmlFor="stepReferenced" >Protection globale du portail</label>
                </div>
              </TabPanel>
            </TabView>
          </div>
          <div className="flex-grow-1 flex flex-column">
            <div className="flex-grow-1" style={{ height: 0 }}>
              {steps && steps.length > 0 &&
                <DataTable
                  selectionMode={code.global ? null : 'checkbox'}
                  selection={selectedSteps} onSelectionChange={(e) => setSelectedSteps(e.value)}
                  scrollable scrollHeight="flex" value={steps}
                  dataKey="id"
                  sortMode="multiple"
                  removableSort
                  globalFilterFields={['name','enabled']}
                  filters={filters}>
                  <Column selectionMode={code.global ? null : "multiple"} headerStyle={{ width: '3rem' }}></Column>
                  <Column filter field={"name"} header={"Nom"} />
                  {/* <Column field={"type"} header={"Type"} body={fieldTypeTemplate} /> */}
                  {/* <Column field={"required"} header={"Requis"} /> */}
                </DataTable>
              }
            </div>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default CodeEdit;
