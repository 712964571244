import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { appContext } from '../../../App'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function TemplatesDialog(props) {
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)
    const [templates, setTemplates] = useState([])
    const [expandedRows, setExpandedRows] = useState([]);
    const [search, setSearch] = useState('');

    const loadTemplates = () => {
        AppContext.api.get("plans/portal/step_templates").then((res) => {
            if (res?.data)
                setTemplates(res.data)
        });
    }

    useEffect(() => {
        if (props?.visible) {
            loadTemplates()
            setVisible(props.visible)
        }
    }, [props.visible]);

    const closeDialog = () => {
        props.onClose()
        setVisible(false)
    }

    const importTemplates = (ids) => {
        AppContext.api.get("plans/portal/step_templates/import/"+ids.join(',')).then((res) => {
            AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: res.data.length+' annonces importées', life: 3000 });
            props.onImport(res.data)
        });
    }

    const actionBtns = (template) => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button
                        onClick={() => importTemplates([template.id])}
                        text raised
                        label="Importer"
                    ></Button>
                </span>
            </React.Fragment>
        );
    };

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
        </div>
    );

    const getIds = (section) => {
        let ts = templates.filter((t) => {
            return (t.section == section)
        }).map((t) => {return t.id})
        importTemplates(ts)

    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="vertical-align-middle ml-2 font-bold line-height-3">{data.section}</span>
                <Button style={{float:'right'}} label="Importer le groupe" text raised onClick={() => getIds(data.section)} />
            </React.Fragment>
        );
    };

    const startContent = (
        <React.Fragment>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={search}
              placeholder="Rechercher"
              onChange={(e) => setSearch(e.target.value)} />
          </span>
        </React.Fragment>
      );

    return (
        <React.Fragment>
            {<Dialog
                header={startContent}
                footer={footerContent}
                visible={isVisible} style={{ width: '800px' }} height="90vh" onHide={closeDialog}>
                {templates.length > 0 && <DataTable value={templates.filter(item => item.name.includes(search))}
                    rowGroupMode="subheader" groupRowsBy="section"
                    expandableRowGroups
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowGroupHeaderTemplate={headerTemplate}
                    sortField="section">
                    <Column field="name" header="Nom"></Column>
                    <Column header="" style={{ width: '45px' }} body={actionBtns}></Column>
                </DataTable>}
            </Dialog>}
        </React.Fragment>
    );
}

export default TemplatesDialog;
