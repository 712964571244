import React, { useState, useEffect, useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import API from "../../../api";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import moment from 'moment';
moment.locale();

function Medias() {
  const [search, debouncedSearch, setSearch] = useDebounce('', 400);
  const [medias, setMedias] = useState([])
  const fileUploader = useRef(null)
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
});


  const loadMedias = () => {
    let params = []
    API.get("medias", params).then((res) => {
      if (res?.data?.files)
        setMedias(res.data.files);
    });
  }

  useEffect(() => {
    loadMedias()
  }, [search]);

  const startContent = (
    <React.Fragment>
      <h2 className="m-0">Médias</h2>
    </React.Fragment>
  );

  const onUpload = () => {
    loadMedias()
    // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };
  const customUpload = async (event) => {
    let formData = new FormData();
    event.files.forEach(file => {
      formData.append("files[]", file)
    });

    API.getInstance().post("medias", formData).then((res) => {
      fileUploader.current.clear();
      fileUploader.current.setUploadedFiles(event.files);
      loadMedias()

    })
    return true;
  };

  const endContent = (
    <React.Fragment>
      <FileUpload
        auto
        mode="basic"
        chooseOptions={{ label: 'Uploader' }}
        ref={fileUploader}
        multiple
        customUpload
        uploadHandler={customUpload}
        name="files[]"
        // accept="image/*,videos/*"
        // maxFileSize={1000000}
        onUpload={onUpload} />
    </React.Fragment>
  );

  const actionBtns = (media) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
        <Button
            icon="pi pi-link"
            onClick={(event) => window.open(media.url)}
            text raised
          ></Button>
          <Button
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveMedia(event, media)}
            severity="danger"
            text raised
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const confirmRemoveMedia = (event, media) => {

    const accept = () => {
      API.getInstance().delete("medias?file="+media.name).then((res) => {
        if(res.data == 1)
          loadMedias()
      });
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer ce media',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  return (
    <React.Fragment>
      <ConfirmPopup />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <DataTable
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={medias}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          scrollHeight="flex"
          className="fullHeightTable"
          sortMode="multiple"
          removableSort
          globalFilterFields={['name']}
          filters={filters}
        >
          <Column field="ext" header="Aperçu" body={(file) => {
            let imgTypes = ['jpg', 'jpeg', 'png', 'svg', 'gif']
            return imgTypes.includes(file.ext) ?
            <Image src={file.url} alt="Image" width="100px" preview /> : <div className="text-center" style={{width:'100px'}}><span className="text-5xl pi pi-eye-slash"></span></div>
          }}></Column>
          <Column field="name" header="Nom" sortable filter></Column>
          <Column field="created_at" header="Date" sortable body={(item) => moment.unix(item.created_at).format('LL')}></Column>
          <Column header="" style={{ width: '85px' }} body={actionBtns}></Column>
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default Medias;
