import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { getPathByName } from "../../../routesUtils"
import {appContext} from '../../../App'

function UserProfil() {
    const navigate = useNavigate();
    const [portals, setPortals] = useState([]);
    const [steptemplates, setStepTemplates] = useState([]);
    const [forms, setForms] = useState([]);
    const [users, setUsers] = useState([]);
    const [infos, setInfos] = useState([]);
    let { portalId } = useParams(null);
    const my = useRouteLoaderData('root');
    const AppContext = useContext(appContext)

    const loadDashboard = () => {
        let params = [
        ]
        AppContext.api.get("/dashboard/user", params).then((res) => {
            if (res?.data?.infos) setInfos(res.data.infos);
        });
    }

    useEffect(() => {
        loadDashboard()
    }, []);

    return (
        <div className="flex flex-column">
            <div className="flex flex-grow-1">
                <Card
                    title="Aide"
                    className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingContentCard"
                >
                <div dangerouslySetInnerHTML={{__html: infos}} />
                </Card>
            </div>
        </div>
    )
}

export default UserProfil;
