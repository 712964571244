import React, { useState, useEffect, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { getPathByName } from "../../../routesUtils"
import { appContext } from '../../../App'

function Codes() {
    const navigate = useNavigate();
    const [codes, setCodes] = useState([]);
    const [search, debouncedSearch, setSearch] = useDebounce('', 2000);
    let { portalId } = useParams(null);
    const AppContext = useContext(appContext)
    const [filters, setFilters] = useState({
        identifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        accesskey: { value: null, matchMode: FilterMatchMode.CONTAINS },
        enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const loadCodes = () => {
        let fields = [
            'codes.id',
            'codes.identifier',
            'codes.accesskey',
            'codes.enabled',
            'codes.global'
        ]
        let params = [{key:'include',value:fields.join(',')}]
        if (search.length > 0) params.push({ key: "search", value: search });
        AppContext.api.getRecords("codes", params).then((res) => {
            setCodes(res.data.records);
        });
    }

    useEffect(() => {
        loadCodes()
    }, [debouncedSearch]);

    const startContent = (
        <React.Fragment>
            <h2 className="m-0">Code d'accès</h2>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    value={search}
                    placeholder="Rechercher un utilisateur"
                    onChange={(e) => setSearch(e.target.value)} />
            </span> */}
        </React.Fragment>
    );

    const endContent = (
        <React.Fragment>
            <Button
                label="Ajouter"
                icon="pi pi-check"
                severity="success"
                onClick={
                    () => navigate(getPathByName('portalCreateCode', { portalId: portalId }))
                }>
            </Button>
        </React.Fragment>
    );

    const actionBtns = (item) => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button
                        icon="pi pi-pencil"
                        onClick={() => navigate(getPathByName('portalEditCode', { portalId: portalId, codeId: item.id }))}
                        text raised
                    ></Button>
                    <Button
                        icon="pi pi-trash"
                        onClick={(event) => confirmRemoveCode(event, item)}
                        severity="danger"
                        text raised
                    ></Button>
                </span>
            </React.Fragment>
        );
    };

    const confirmRemoveCode = (event, code) => {

        const accept = () => {
            AppContext.api.deleteRecords("codes", [code.id]).then((res) => {
                loadCodes()
            });
        };

        confirmPopup({
            target: event.currentTarget,
            message: 'Voulez-vous supprimer ce code?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            accept
        });
    };

    const globalBody = (field) => {
        return field.global ? <span>Portail entier</span> : <span>Par démarches</span>
    };

    const enabledBody = (field) => {
        return field.enabled ? <strong>Activé</strong> : <span>Désactivé</span>
    };

    const enabledRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={[{ value: true, label: 'Activé' }, { value: false, label: 'Désactivé' }]}
            onChange={(e) =>
                options.filterApplyCallback(e.value)}
            placeholder="Statut"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }} />
    };

    return (
        <React.Fragment>
            <ConfirmPopup />
            <Card
                className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
                header={
                    <Toolbar
                        start={startContent}
                        end={endContent}
                        className="shadow-none border-bottom-1 surface-border border-noround-bottom"
                    />
                }
            >
                <DataTable
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    value={codes}
                    tableStyle={{ minWidth: "50rem" }}
                    scrollable
                    scrollHeight="flex"
                    className="fullHeightTable"
                    sortMode="multiple"
                    removableSort
                    globalFilterFields={['name','enabled']}
                    filters={filters}
                >
                    <Column field="identifier" filter sortable header="Identifiant"></Column>
                    <Column field="accesskey" filter sortable header="Clé d'accès"></Column>
                    <Column field="global" header="Protection" body={globalBody}></Column>
                    <Column field="enabled" header="Statut" body={enabledBody}
                        dataType="boolean"
                        filter
                        filterElement={enabledRowFilterTemplate}
                        sortable></Column>
                    <Column header="" body={actionBtns} style={{ width: '85px' }}></Column>
                </DataTable>
            </Card>
        </React.Fragment>
    );
}

export default Codes;
