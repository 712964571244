import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from 'primereact/inputtextarea';
import { appContext } from '../../../App'
import moment from 'moment';

function RevisionDialog(props) {
  const [currentRevision, setCurrentRevision] = useState(undefined);
  const [isVisible, setVisible] = useState(false);
  const [currentContent, setCurrentContent] = useState('');
  const AppContext = useContext(appContext)

  useEffect(() => {
    if (props.currentRevision) {
      setCurrentRevision(props.currentRevision)
      setVisible(true)
    }
  }, [props.currentRevision]);

  useEffect(() => {
    if(currentRevision && currentRevision?.id){
      AppContext.api.getRecord("form_revisions",[currentRevision.id])
      .then((response) => {
        if(response?.data)
          setCurrentContent(response?.data)
      })
    }
  }, [currentRevision]);

  const closeDialog = () => {
    props.onClose()
    setCurrentRevision(undefined)
    setVisible(false)
  }

  const loadRevision = () => {
    props.onLoadRevision(currentContent)
    setCurrentRevision(undefined)
    setVisible(false)
  }

  const footerContent = (
    <div>
      <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
      <Button label="Récupérer" icon="pi pi-check" onClick={loadRevision} />
    </div>
  );

  const fieldsTypeStart = [
    {
      label: 'Séparateur de section',
      name: 'section',
      position: 'start'
    },
    {
      label: 'Champ texte',
      name: 'inputtext',
      position: 'start'
    },
    {
      label: 'Champ numérique',
      name: 'inputnumber',
      position: 'start'
    },
    {
      label: 'Email',
      name: 'inputemail',
      position: 'start'
    },
    {
      label: 'Date',
      name: 'inputdate',
      position: 'start'
    },
    {
      label: 'Zone de texte',
      name: 'textarea',
      position: 'start'
    },
    {
      label: 'Selection',
      name: 'select',
      position: 'start'
    },
    {
      label: 'Bouton radios',
      name: 'radio',
      position: 'start'
    },
    {
      label: 'Cases à cocher',
      name: 'checkbox',
      position: 'start'
    }
  ];

  const fieldLabelTemplate = (field) => {
    let el = fieldsTypeStart.find((e) => e.name == field.type)
    let type = (el) ? '- '+el.label : '- Aucun type reconnu'
    let condition = (field?.isVisibleCondition && field.isVisibleCondition) ? <span><br />- Condition de visibilité</span> : ''
    return <><strong>{field.label}</strong><br />{type}{condition}</>
  };

  const fieldTypeTemplate = (field) => {
    let el = fieldsTypeStart.find((e) => e.name == field.type)
    return (el) ? <>{el.label}</> : <>-</>
  };

  const fieldRequiredTemplate = (field) => {
    if(field.type == "section") return '-'
    return field.required ? <strong>Oui</strong> : <span>Non</span>
  };

  return (
    <React.Fragment>
      <Dialog header="Révisions" footer={footerContent} visible={isVisible} style={{ minWidth: '50vw', maxWidth: '85vw' }} onHide={closeDialog}>
        <div className="flex flex-row gap-3" style={{minHeight:'60vh'}}>
          <div className="flex-grow-1" style={{ width: '300px' }}>
            <DataTable value={props.revisions}
              selectionMode="single" selection={currentRevision}
              onSelectionChange={(e) => setCurrentRevision(e.value)}>
              <Column field={"created_at"} header={"Date"} body={(item) => moment(item.created_at).format('LLL')} />
            </DataTable>
            </div>
          <div style={{ width: '800px' }} className="flex-grow-1 overflow-y" >
          <DataTable scrollable scrollHeight="flex" value={currentContent.fields}>
                  <Column field={"label"} header={"Champ"} body={fieldLabelTemplate} />
                  <Column field={"required"} header={"Requis"} body={fieldRequiredTemplate} />
                </DataTable>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default RevisionDialog;
