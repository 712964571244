import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import API from "../../../api";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


function MediasPicker(props) {
    const [isVisible, setVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [medias, setMedias] = useState([])
    const fileUploader = useRef(null)
    const [customUrl, setCustomUrl] = useState('');
    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const loadMedias = () => {
        let params = [
        ]
        if (props?.options) {
            if (props.options?.extensions) params.push({ key: "extensions", value: props.options?.extensions })
        }
        if (search.length > 0) params.push({ key: "search", value: search });
        API.get("medias", params).then((res) => {
            if (res?.data?.files)
                setMedias(res.data.files);
        });
    }

    useEffect(() => {
        if (props.visible) {
            loadMedias()
            setVisible(true)
        }
    }, [props.visible]);

    const closeDialog = () => {
        if (props.onCancel)
            props.onCancel();
        setVisible(false)
        setCustomUrl('')
    }

    const onSelect = (url) => {
        if (props.onSelect)
            props.onSelect(url);
        setVisible(false)
        setCustomUrl('')
    }

    const onUpload = () => {
        loadMedias()
        // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const customUpload = async (event) => {
        let formData = new FormData();
        formData.append('test', 'ok');
        event.files.forEach(file => {
            formData.append("files[]", file)
        });

        API.getInstance().post("medias", formData).then((res) => {
            fileUploader.current.clear();
            fileUploader.current.setUploadedFiles(event.files);
            loadMedias()

        })
        return true;
    };
    const icon = (<i className="pi pi-search"></i>)

    const footerContent = (
        <div className="flex align-items-center align-content-center justify-content-center">
            <div style={{ display: 'inline' }}><FileUpload
                auto
                mode="basic"
                chooseOptions={{ label: 'Uploader' }}
                ref={fileUploader}
                multiple
                customUpload
                uploadHandler={customUpload}
                name="files[]"
                accept="image/*"
                maxFileSize={1000000}
                onUpload={onUpload} /></div>
        </div>
    );

    const actionBtns = (media) => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button
                        icon="pi pi-link"
                        onClick={(event) => window.open(media.url)}
                        text raised
                    ></Button>
                    <Button
                        onClick={(event) => onSelect(media.url)}
                        text raised
                    >Insérer</Button>
                </span>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {isVisible && <Dialog header="Médias" footer={footerContent} visible={isVisible} style={{ minWidth: '650px', maxWidth: '1050px' }} onHide={closeDialog}>
                <div className="mb-3">
                    <div className="p-inputgroup flex-1">
                        <InputText value={customUrl} onChange={(e) => setCustomUrl(e.target.value)} placeholder="URL" className="w-full"></InputText>
                        <Button icon="pi pi-arrow-right" severity="success" onClick={() => onSelect(customUrl)} />
                    </div>
                </div>
                <DataTable
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    value={medias}
                    tableStyle={{ minWidth: "50rem" }}
                    scrollable
                    scrollHeight="300px"
                    sortMode="multiple"
                    removableSort
                    globalFilterFields={['name']}
                    filters={filters}
                >
                    <Column field="ext" header="Aperçu" body={(file) => {
                        let imgTypes = ['jpg', 'jpeg', 'png', 'svg', 'gif']
                        return imgTypes.includes(file.ext) ?
                            <Image src={file.url} alt="Image" width="100px" preview /> : <div className="text-center" style={{ width: '100px' }}><span className="text-5xl pi pi-eye-slash"></span></div>
                    }}></Column>
                    <Column field="name" header="Nom" sortable filter></Column>
                    <Column header="" style={{ width: '125px' }} body={actionBtns}></Column>
                </DataTable>

            </Dialog>}
        </React.Fragment>
    );
}

export default MediasPicker;
