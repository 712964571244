
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useParams, useLoaderData, useRouteLoaderData } from "react-router-dom";
import API from "../api";

import Sidebar from './Sidebar';

function Layout(props) {
    let { portalId } = useParams(null);
    const my = useRouteLoaderData('root');

    return (
        <div className="App">
            <div className="main">
                <Sidebar my={my} mode={props.mode} portalId={portalId} />
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout;