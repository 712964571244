import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { appContext } from '../../../App'
import fontawesome from '../../layout/Icon/fontawesome'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function LinkDialog(props) {
    const [currentLink, setCurrentLink] = useState(undefined);
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)

    useEffect(() => {
        if (props.currentLink) {
            setCurrentLink(props.currentLink)
            setVisible(true)
        }
    }, [props.currentLink]);

    const closeDialog = () => {
        props.onClose()
        setCurrentLink(undefined)
        setVisible(false)
    }

    const saveLink = () => {
        props.onChange(currentLink)
        setCurrentLink(undefined)
        setVisible(false)
    }

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
            <Button label="Modifier" icon="pi pi-pencil" onClick={saveLink} />
        </div>
    );


    const startContent = (
        <React.Fragment>
            Modification du lien
        </React.Fragment>
    );

    const setProperty = (property, value) => {
        setCurrentLink({ ...currentLink, [property]: value });
    }

    const iconTemplate = (link) => {
        return (
            <div className="flex align-items-center">
                <span className={'fa ' + link} />
                <span className="ml-2">{link}</span>
            </div>
        );
    };

    return (
        <React.Fragment>
            {currentLink && <Dialog
                header={startContent}
                footer={footerContent}
                visible={isVisible} style={{ width: '400px' }} height="90vh" onHide={closeDialog}>

                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkIcon">Titre</label>
                    <Dropdown 
                        filter 
                        valueTemplate={iconTemplate} 
                        itemTemplate={iconTemplate} 
                        id="linkIcon" 
                        value={currentLink.icon} 
                        onChange={(e) => setProperty('icon', e.value)} 
                        options={fontawesome}
                        placeholder="Selectionner une icone" 
                        className="w-full" />
                </div>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkTitle">Titre</label>
                    <InputText id="linkTitle" value={currentLink.title}
                        onChange={(e) => setProperty('title', e.target.value)}
                        placeholder="Titre" className="mr-2" />
                </div>
                {<div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkUrl">URL</label>
                    <InputText id="linkUrl" value={currentLink.url}
                        onChange={(e) => setProperty('url', e.target.value)}
                        placeholder="https://..." className="mr-2" />
                </div>}
            </Dialog>}
        </React.Fragment>
    );
}

export default LinkDialog;
