const flatRoutes = [
    { name: 'home', path: '/' },
    { name: 'me', path: '/me' },
    { name: 'help', path: '/help' },
    { name: 'config', path: '/config' },
    { name: 'configPortals', path: '/config/portals' },
    { name: 'configCreatePortal', path: '/config/portals/create' },
    { name: 'configEditPortal', path: '/config/portals/:portalId/edit' },
    { name: 'configUsers', path: '/config/users' },
    { name: 'configCreateUser', path: '/config/users/create' },
    { name: 'configEditUser', path: '/config/users/:userId/edit' },
    { name: 'configSteptemplates', path: '/config/step_templates' },
    { name: 'configCreateSteptemplate', path: '/config/step_templates/create' },
    { name: 'configEditSteptemplate', path: '/config/step_templates/:steptemplateId/edit' },
    { name: 'configForms', path: '/config/forms' },
    { name: 'configCreateForm', path: '/config/forms/create' },
    { name: 'configEditForm', path: '/config/forms/:formId/edit' },
    { name: 'configMedias', path: '/config/medias' },
    { name: 'configPlans', path: '/config/plans' },
    { name: 'configSettings', path: '/config/settings' },
    { name: 'portalDashboard', path: '/portal/:portalId' },
    { name: 'portalSteps', path: '/portal/:portalId/steps' },
    { name: 'portalCreateStep', path: '/portal/:portalId/steps/create' },
    { name: 'portalEditStep', path: '/portal/:portalId/steps/:stepId/edit' },
    { name: 'portalForms', path: '/portal/:portalId/forms' },
    { name: 'portalCreateForm', path: '/portal/:portalId/forms/create' },
    { name: 'portalEditForm', path: '/portal/:portalId/forms/:formId/edit' },
    { name: 'portalSubmissions', path: '/portal/:portalId/submissions' },
    { name: 'portalViewSubmission', path: '/portal/:portalId/submissions/:submissionId/view' },
    { name: 'portalMedias', path: '/portal/:portalId/medias' },
    { name: 'portalCategories', path: '/portal/:portalId/categories' },
    { name: 'portalCodes', path: '/portal/:portalId/codes' },
    { name: 'portalCreateCode', path: '/portal/:portalId/codes/create' },
    { name: 'portalEditCode', path: '/portal/:portalId/codes/:codeId/edit' },
    { name: 'portalPermissions', path: '/portal/:portalId/permissions' },
    { name: 'portalEdit', path: '/portal/:portalId/edit' }
];

function getPathByName(routeName, params = {}) {
    const route = flatRoutes.find(r => r.name === routeName);
    let path = route?.path;

    if (path) {
        Object.entries(params).forEach(([key, value]) => {
            path = path.replace(`:${key}`, value);
        });
    }

    return path || '/';
}

export { getPathByName }