import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useRouteError } from "react-router-dom";
import { Button } from "primereact/button";

function Errors() {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <React.Fragment>
        <div className="App flex align-items-center justify-content-center">
        <h2>Oups...</h2>
        <h3>Une erreur est survenue</h3>
        <Button onClick={() => window.history.back()} label="Retour" />
        </div>
    </React.Fragment>
  );
}

export default Errors;
