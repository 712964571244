import React, { useState, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import {appContext} from '../../../App'


function CategoryDialog(props) {
    const [currentCategory, setCurrenCategory] = useState(undefined);
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)
    const [invalids, setInvalids] = useState({})

    useEffect(() => {
        if (props.category) {
            setCurrenCategory(props.category)
            setVisible(true)
        }
    }, [props.category]);

    const closeDialog = () => {
        props.onCancel();
        setCurrenCategory(undefined)
        setVisible(false)
    }

    const saveCategory = () => {
        let errors = {}
        if(currentCategory.name == '') errors['name'] = 'Champs "Nom" requis';
        setInvalids(errors)
        if(Object.keys(errors).length > 0){
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({ 
                severity: 'error', 
                summary: 'Erreurs', 
                detail: msg.map((m,i) => <div key={i}>{m}</div>), 
                life: 3000 
            });
            return;
        }

        props.onSave(currentCategory);
        setCurrenCategory(undefined)
        setVisible(false)
    }

    const setName = (name) => {
        setCurrenCategory({ ...currentCategory, name: name });
    }

    const setColor = (color) => {
        setCurrenCategory({ ...currentCategory, color: "#"+color });
    }

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
            <Button label={currentCategory && currentCategory.hasOwnProperty('id') ? "Modifier" : "Ajouter"} icon="pi pi-check" onClick={saveCategory} className="mr-0" />
        </div>
    );

    return (
        <React.Fragment>
            {currentCategory && <Dialog header="Catégorie" footer={footerContent} visible={isVisible} style={{ width: '300px' }} onHide={closeDialog}>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="categoryLabel">Nom</label>
                    <InputText 
                        id="categoryLabel" 
                        value={currentCategory.name} 
                        onChange={(e) => setName(e.target.value)} placeholder="Nom" 
                        className={"mr-2"+(invalids.hasOwnProperty('name') ? ' p-invalid' : '')}
                        />
                </div>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="categoryLabel">Couleur</label>
                    <ColorPicker value={currentCategory.color} onChange={(e) => setColor(e.value)} inline />
                </div>
                
            </Dialog>}
        </React.Fragment>
    );
}

export default CategoryDialog;
