import React, { useState, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { v4 as uuidv4 } from 'uuid';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import {appContext} from '../../../App'

function FormEdit(props) {
    const [currentField, setCurrentField] = useState(undefined);
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)

    useEffect(() => {
        if (props.field) {
            setCurrentField(props.field)
            setVisible(true)
        }
    }, [props.field]);

    const closeDialog = () => {
        props.onCancel();
        setCurrentField(undefined)
        setVisible(false)
    }

    const saveField = () => {
        let errors = {}
        if(currentField.label == '') errors['label'] = 'Champs "Label" requis.'
        if(currentField.uid == '') errors['uid'] = 'Champs "Identifiant unique" requis.'
        
        if (Object.keys(errors).length > 0) {
          let msg = [];
          for (const name in errors) msg.push(errors[name]) 
          AppContext.showNotification({
            severity: 'error',
            summary: 'Erreurs',
            detail: msg.map((m, i) => <div key={i}>{m}</div>),
            life: 3000
          });
          return;
        }

        props.onSave(currentField);
        setCurrentField(undefined)
        setVisible(false)
    }

    const setLabel = (label) => {
        setCurrentField({ ...currentField, label: label });
    }

    const setRequired = (required) => {
        setCurrentField({ ...currentField, required: required });
    }

    const setNote = (note) => {
        setCurrentField({ ...currentField, note: note });
    }

    const setPlaceholder = (placeholder) => {
        setCurrentField({ ...currentField, placeholder: placeholder });
    }

    const setIsVisibleCondition = (condition) => {
        setCurrentField({ ...currentField, isVisibleCondition: condition });
    }

    const setIsVisibleField = (visible) => {
        setCurrentField({ ...currentField, isVisibleField: visible });
    }

    const setIsVisibleValue = (value) => {
        setCurrentField({ ...currentField, isVisibleValue: value });
    }

    const setIsVisibleOperator = (operator) => {
        setCurrentField({ ...currentField, isVisibleOperator: operator });
    }

    const setFieldUID = (uid) => {
        setCurrentField({ ...currentField, uid: uid });
    }

    const setOptions = (options) => {
        if (currentField && currentField?.options) {
            setCurrentField({ ...currentField, options: options });
        }
      }

    const visibleOperators = [
        '=', '!=', '<', '<=', '>', '>='
    ]

    const addOption = () => {
        let options = currentField.options;
        options.push({ label: 'option', uid: uuidv4() })
        setCurrentField({ ...currentField, options: options });
    }

    const setOptionLabel = (option, label) => {
        var array = [...currentField.options];
        var index = array.indexOf(option)
        let options = currentField.options;
        options[index].label = label
        setCurrentField({ ...currentField, options: options });
    }

    const setOptionUID = (option, uid) => {
        var array = [...currentField.options];
        var index = array.indexOf(option)
        let options = currentField.options;
        options[index].uid = uid
        setCurrentField({ ...currentField, options: options });
    }

    const removeOption = (option) => {
        var array = [...currentField.options];
        var index = array.indexOf(option)
        if (index !== -1) {
            array.splice(index, 1);
            setCurrentField({ ...currentField, options: array });
        }
    }

    const actionOptionsBtns = (option) => {
        return (
          <React.Fragment>
            <span className="p-buttonset">
              <Button
                text raised
                icon="pi pi-trash"
                onClick={(event) => removeOption(option)}
                size="small"
                severity="danger"
              ></Button>
            </span>
          </React.Fragment>
        );
      };

    const optionLabelBody = (option) => {
        return <>
            <Inplace closable>
                <InplaceDisplay>{option.label || 'Click to Edit'}</InplaceDisplay>
                <InplaceContent>
                    <InputText value={option.label} onChange={(e) => setOptionLabel(option, e.target.value)} autoFocus />
                </InplaceContent>
            </Inplace>
        </>
        return <><span>{option.label}</span><br /><small>{option.uid}</small></>
    }

    const optionUIDBody = (option) => {
        return <>
            <Inplace closable>
                <InplaceDisplay><small>{option.uid || 'Click to Edit'}</small></InplaceDisplay>
                <InplaceContent>
                    <InputText value={option.uid} onChange={(e) => setOptionUID(option, e.target.value)} autoFocus />
                </InplaceContent>
            </Inplace>
        </>
        return <><span>{option.label}</span><br /><small>{option.uid}</small></>
    }

    

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
            <Button label="Modifier" icon="pi pi-check" onClick={saveField} />
        </div>
    );

    return (
        <React.Fragment>
            {currentField && <Dialog header="Edition du champ" footer={footerContent} visible={isVisible} style={{ minWidth: '50vw', maxWidth: '75vw' }} onHide={closeDialog}>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="fieldLabel">Label</label>
                    <InputText id="fieldLabel" value={currentField.label} onChange={(e) => setLabel(e.target.value)} placeholder="Label" className="mr-2" />
                    
                    <Inplace closable className="mb-2">
                        <InplaceDisplay><small>Identifiant unique : {currentField.uid || 'Click to Edit'}</small></InplaceDisplay>
                        <InplaceContent>
                            <InputText value={currentField.uid} onChange={(e) => setFieldUID(e.target.value)} autoFocus />
                        </InplaceContent>
                    </Inplace>
                </div>
                {currentField.type != 'section' &&
                    <>
                        <div className="flex flex-row gap-3 mb-3 mt-2">
                            <InputSwitch id="fieldRequired" checked={currentField.required} onChange={(e) => setRequired(e.value)} />
                            <label htmlFor="fieldRequired">Champ requis</label>
                        </div>
                        <div className="flex flex-column mb-3 gap-3">
                            <label htmlFor="fieldNote">Note</label>
                            <InputText id="fieldNote" value={currentField.note} onChange={(e) => setNote(e.target.value)} placeholder="Note" className="mr-2" />
                        </div>
                        {['inputtext', 'inputemail'].includes(currentField.type) && <div className="flex flex-column mb-3 gap-3">
                            <label htmlFor="fieldPlaceholder">Placeholder</label>
                            <InputText id="fieldPlaceholder" value={currentField.placeholder} onChange={(e) => setPlaceholder(e.target.value)} placeholder="Placeholder" className="mr-2" />
                        </div>}
                        {['radio', 'checkbox', 'select'].includes(currentField.type) && <div className="flex flex-column mb-3 gap-3">
                            <div className="flex-grow-1">
                                <div className="flex">
                                    <div className="flex-grow-1"><label htmlFor="fieldOptions">Options</label></div>
                                    <Button text raised icon="pi pi-plus" onClick={() => addOption()} />
                                </div>
                                <div className="">
                                    <DataTable value={currentField.options} reorderableRows onRowReorder={(e) => setOptions(e.value)}>
                                        <Column rowReorder style={{ width: '3rem' }} />
                                        <Column key={"label"} columnKey={"label"} header={"Label"} body={optionLabelBody} />
                                        <Column key={"uid"} columnKey={"uid"} header={"Identifiant unique"} body={optionUIDBody} />
                                        <Column header="" body={actionOptionsBtns} style={{ width: '45px' }}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>}
                        <div className="flex flex-row gap-3 mb-3 mt-6">
                            <InputSwitch id="fieldIsCondition" checked={currentField.isVisibleCondition} onChange={(e) => setIsVisibleCondition(e.value)} />
                            <label htmlFor="fieldIsCondition">Condition de visibilité</label>
                        </div>
                        {currentField.isVisibleCondition && <div className="flex flex-row gap-3 mb-3 ">
                            <div className="flex-grow-1 flex flex-column">
                                <Dropdown
                                    value={currentField.isVisibleField}
                                    onChange={(e) => setIsVisibleField(e.value)}
                                    options={props.fields}
                                    optionValue="uid"
                                    optionLabel="label"
                                    placeholder="Sélectionner un champ" />
                            </div>
                            <div className="flex flex-column">
                                <Dropdown
                                    value={currentField.isVisibleOperator}
                                    onChange={(e) => setIsVisibleOperator(e.value)}
                                    options={visibleOperators}
                                    placeholder="Comparaison" />
                            </div>
                            <div className="flex-grow-1 flex flex-column">
                                <InputText id="fieldIsVisibleValue" value={currentField.isVisibleValue} onChange={(e) => setIsVisibleValue(e.target.value)} placeholder="Valeur" className="mr-2" />
                            </div>
                        </div>}
                    </>
                }
            </Dialog>}
        </React.Fragment>
    );
}

export default FormEdit;
