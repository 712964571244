import BaseCodeBlock from "@tiptap/extension-code-block";
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'

export default CodeBlockLowlight.extend({
  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (this.editor.isActive("codeBlock")) {
          return this.editor.commands.insertContent("\t");
        }
      },
    };
  },
});