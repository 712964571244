import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import {appContext} from '../../../App'

function StepAIDialog(props) {
    const [isVisible, setVisible] = useState(false);
    const [prompt, setPrompt] = useState('');
    const AppContext = useContext(appContext)

    useEffect(() => {
        if (props.visible) {
            setVisible(true)
        }
        else{
            setVisible(false)
        }
    }, [props.visible]);

    const closeDialog = () => {
        props.onCancel();
        setPrompt("")
        setVisible(false)
    }

    const generate = () => {
        AppContext.api.post("/ai/step", { prompt: prompt }).then((res) => {
            props.onGenerate(res.data);
        });
        setPrompt("")
        setVisible(false)
    }

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
            <Button label="Générer" icon="pi pi-check" onClick={generate} />
        </div>
    );

    return (
        <React.Fragment>
            <Dialog header="Génération d'annonce avec I.A." footer={footerContent} visible={isVisible} style={{ minWidth: '50vw', maxWidth: '75vw' }} onHide={closeDialog}>
                <div className="flex flex-column mb-3 gap-3">
                    <InputTextarea autoResize id="promptInput" value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="Description de votre annonce..." />
                </div>
            </Dialog>
        </React.Fragment>
    );
}

export default StepAIDialog;
