import axios from 'axios';



class Api {

  static init = (function () {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true
      
    });

    this.instance = instance;

    // this.instance.interceptors.response.use(function (response) {
    //   // Any status code that lie within the range of 2xx cause this function to trigger
    //   // Do something with response data
    //   return response;
    // }, function (error) {
    //   // Any status codes that falls outside the range of 2xx cause this function to trigger
    //   // Do something with response error
    //   return Promise.reject(error);
    // });
  })

  static getInstance = () => {
    return this.instance
  }


  static buildParams = (params = []) => {
    let paramUrl = '';
    if (params.length > 0) {
      paramUrl = '?';
      params.forEach((param, index) => {
        paramUrl += `${param.key}=${param.value}`;
        if (index < params.length - 1) {
          paramUrl += '&';
        }
      });
    }
    return paramUrl;
  }

  static get = async (url) => {
    return await this.instance.get(url);
  };

  static post = async (url, body) => {
    return await this.instance.post(url, body);
  };

  static put = async (url, body) => {
    return await this.instance.put(url, body);
  };

  static delete = async (url) => {
    return await this.instance.delete(url);
  };

  static getRecords = async (table, params = []) => {
    let paramUrl = this.buildParams(params);
    return await this.instance.get(`records/${table}${paramUrl}`);
  };
  static getRecord = async (table, recordIds, params = []) => {
    let paramUrl = this.buildParams(params);
    return await this.instance.get(`records/${table}/${recordIds.join(',')}${paramUrl}`);
  };
  static postRecords = async (table, body) => {
    return await this.instance.post(`records/${table}`, body);
  };
  static putRecords = async (table, recordIds, body) => {
    return await this.instance.put(`records/${table}/${recordIds.join(',')}`, body);
  };
  static deleteRecords = async (table, recordIds) => {
    return await this.instance.delete(`records/${table}/${recordIds.join(',')}`);
  };
}

Api.init();

export default Api