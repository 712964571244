import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { useNavigate, useParams } from "react-router-dom";
import { getPathByName } from "../../../routesUtils"
import { appContext } from '../../../App'

function DashboardAdmin() {
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [forms, setForms] = useState([]);
  const [users, setUsers] = useState([]);
  const [infos, setInfos] = useState([]);
  const [stepsTotal, setStepsTotal] = useState(0);
  const [formsTotal, setFormsTotal] = useState(0);
  const [usersTotal, setUsersTotal] = useState(0);

  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)

  const loadDashboard = () => {
    let params = [
    ]
    AppContext.api.get("/dashboard/portal", params).then((res) => {
      let stepsMenu = []
      let formsMenu = []
      let usersMenu = []
      let infosMenu = []

      if(res?.data?.steps?.total) setStepsTotal(res.data.steps.total)
      if(res?.data?.forms?.total) setFormsTotal(res.data.forms.total)
      if(res?.data?.users?.total) setUsersTotal(res.data.users.total)

      if (res?.data?.steps?.items) {
        res.data.steps.items.map((step,index) => {
          stepsMenu.push({
            label: step.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link" onClick={() => navigate(getPathByName('portalEditStep', { portalId: portalId, stepId: step.id }))}>
                  {step.name}
                </a>
              </div>)
            }
          })
        })
      }

      if (res?.data?.forms?.items) {
        res.data.forms.items.map((form, index) => {
          formsMenu.push({
            label: form.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link" onClick={() => navigate(getPathByName('portalEditForm', { portalId: portalId, formId: form.id }))}>
                  {form.name}
                </a>
              </div>)
            }
          })
        })
      }

      if (res?.data?.users?.items) {
        res.data.users.items.map((user,index) => {
          usersMenu.push({
            label: user.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link">
                  {user.name}
                </a>
              </div>)
            }
          })
        })
      }
      // const markup = { __html: '<p>some raw html</p>' };
      if (res?.data?.infos) setInfos(res.data.infos);

      setSteps(stepsMenu);
      setForms(formsMenu);
      setUsers(usersMenu);
    });
  }

  useEffect(() => {
    loadDashboard()
  }, []);

  const headerTemplate = (options, title, total, btns) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <span className="font-bold">{title} ({total})</span>
        </div>
        {btns && btns.length > 0 && <div className="flex">
          {btns.map((item, index) => <Button key={index} label={item.label} text raised size="small" onClick={item.onclick}/>)}
        </div>}
      </div>
    );
  };

  const panelPt = {
    root:{className: 'col shadow-none border-1 surface-border flex flex-column flex-grow-1 p-0'},
    toggleableContent: {className: 'flex-grow-1 flex', style:{'height':0}},
    content: { className: 'flex-grow-1 overflow-y' },
  }

  return (
    <React.Fragment>
      <div className="flex flex-column">
        <div className="grid flex-grow-1 p-2">
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Annonces', stepsTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('portalSteps', { portalId: portalId }))}}
            ])}
            pt={panelPt}
            className="mr-2">
            <Menu model={steps} className="w-full border-0" />
          </Panel>
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Formulaires', formsTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('portalForms', { portalId: portalId }))}}
            ])}
            pt={panelPt}
            className="mx-2">
            <Menu model={forms} className="w-full border-0" />
          </Panel>
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Gestionnaires', usersTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('portalPermissions', { portalId: portalId }))}}
            ])}
            pt={panelPt}
            className="ml-2">
            <Menu model={users} className="w-full border-0" />
          </Panel>
        </div>
        <div className="flex flex-grow-1 pt-2">
          <Panel
            header="Informations"
            pt={panelPt}>
            <div dangerouslySetInnerHTML={{__html: infos}} />
            </Panel>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DashboardAdmin;
