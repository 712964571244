import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { Panel } from 'primereact/panel';
import { getPathByName } from "../../../routesUtils"
import API from "../../../api";
import {appContext} from '../../../App'

function DashboardConfig() {
  const navigate = useNavigate();
  const [portals, setPortals] = useState([]);
  const [steptemplates, setStepTemplates] = useState([]);
  const [forms, setForms] = useState([]);
  const [users, setUsers] = useState([]);
  const [infos, setInfos] = useState([]);
  const [stepTemplatesTotal, setStepTemplatesTotal] = useState(0);
  const [formsTotal, setFormsTotal] = useState(0);
  const [usersTotal, setUsersTotal] = useState(0);
  const [portalsTotal, setPortalsTotal] = useState(0);

  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)

  const loadDashboard = () => {
    let params = [
    ]
    AppContext.api.get("/dashboard/config", params).then((res) => {
      let portalsMenu = []
      let steptemplatesMenu = []
      let formsMenu = []
      let usersMenu = []
      let infosMenu = []

      if(res?.data?.step_templates?.total) setStepTemplatesTotal(res.data.step_templates.total)
      if(res?.data?.forms?.total) setFormsTotal(res.data.forms.total)
      if(res?.data?.users?.total) setUsersTotal(res.data.users.total)
      if(res?.data?.portals?.total) setPortalsTotal(res.data.portals.total)

      if (res?.data?.portals?.items) {
        res.data.portals.items.map((portal, index) => {
          portalsMenu.push({
            label: portal.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link" onClick={() => navigate(getPathByName('configEditPortal', { portalId:portal.id}))}>
                {portal.name}
                </a>
              </div>)
            }
          })
        })
      }

      if (res?.data?.step_templates?.items) {
        res.data.step_templates.items.map((steptemplate, index) => {
          steptemplatesMenu.push({
            label: steptemplate.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link" onClick={() => navigate(getPathByName('configEditSteptemplate', {steptemplateId:steptemplate.id}))}>
                {steptemplate.name}
                </a>
              </div>)
            }
          })
        })
      }

      if (res?.data?.forms?.items) {
        res.data.forms.items.map((form, index) => {
          formsMenu.push({
            label: form.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link" onClick={() => navigate(getPathByName('configEditForm', {formId:form.id}))}>
                {form.name}
                </a>
              </div>)
            }
          })
        })
      }

      if (res?.data?.users?.items) {
        res.data.users.items.map((user, index) => {
          usersMenu.push({
            label: user.name,
            template: (item) => {
              return (<div key={index} className='p-menuitem-content'>
                <a className="flex align-items-center p-menuitem-link"
                onClick={() => navigate(getPathByName('configEditUser', {userId:user.id}))}>
                {user.name}
                </a>
              </div>)
            }
          })
        })
      }
      // const markup = { __html: '<p>some raw html</p>' };
      if (res?.data?.infos) setInfos( res.data.infos);

      setPortals(portalsMenu);
      setStepTemplates(steptemplatesMenu);
      setForms(formsMenu);
      setUsers(usersMenu);
    });
  }

  useEffect(() => {
    loadDashboard()
  }, []);

  const headerTemplate = (options, title, total, btns) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <span className="font-bold">{title} ({total})</span>
        </div>
        {btns && btns.length > 0 && <div className="flex">
          {btns.map((item, index) => <Button key={index} label={item.label} text raised size="small" onClick={item.onclick}/>)}
        </div>}
      </div>
    );
  };

  const panelPt = {
    root:{className: 'col shadow-none border-1 surface-border flex flex-column flex-grow-1 p-0'},
    toggleableContent: {className: 'flex-grow-1 flex', style:{'height':0}},
    content: { className: 'flex-grow-1 overflow-y' },
  }

  return (
    <React.Fragment>
      <div className="flex flex-column">
        <div className="grid flex-grow-1 p-2">
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Portails', portalsTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('configPortals'))}}
            ])}
            pt={panelPt}
            className="mr-2">
            <Menu model={portals} className="w-full border-0" />
          </Panel>
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Modèles d\'annonces', stepTemplatesTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('configSteptemplates'))}}
            ])}
            pt={panelPt}
            className="mx-2">
            <Menu model={steptemplates} className="w-full border-0" />
          </Panel>
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Formulaires', formsTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('configForms'))}}
            ])}
            pt={panelPt}
            className="mx-2">
            <Menu model={forms} className="w-full border-0" />
          </Panel>
          <Panel
            headerTemplate={(options) => headerTemplate(options, 'Administrateurs', usersTotal, [
              {label:"Afficher", onclick:() => {navigate(getPathByName('configUsers'))}}
            ])}
            pt={panelPt}
            className="ml-2">
            <Menu model={users} className="w-full border-0" />
          </Panel>
        </div>
        <div className="flex flex-grow-1 pt-2">
          <Panel
            header="Informations"
            pt={panelPt}
            className="mr-2">
            <div dangerouslySetInnerHTML={{__html: infos}} />
          </Panel>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DashboardConfig;
