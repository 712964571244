import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { appContext } from '../../../App'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function VariablesDialog(props) {
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)
    const [variables, setVariables] = useState([])

    const loadVariables = () => {
        AppContext.api.get("variables/portals").then((res) => {
            if(res?.data?.variables)
            setVariables(res.data.variables)
        });
    }

    useEffect(() => {
        if(props.visible){
            loadVariables()
            setVisible(props.visible)
        }
    }, [props.visible]);

    const closeDialog = () => {
        props.onClose()
        setVisible(false)
    }

    const insertVariable = (item) => {
        props.onInsert(item);
        setVisible(false)
    }

    const actionBtns = (variable) => {
        return (
          <React.Fragment>
            <span className="p-buttonset">
              <Button
                onClick={() => insertVariable(variable)}
                text raised
                label="Insérer"
              ></Button>
            </span>
          </React.Fragment>
        );
      };

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
        </div>
    );

    return (
        <React.Fragment>
            {<Dialog header="Variables" footer={footerContent} visible={isVisible} style={{ width: '800px' }} onHide={closeDialog}>
                {variables.length > 0 && <DataTable value={variables}>
                    <Column field="name" header="Nom"></Column>
                    <Column field="value" header="Valeur"></Column>
                    <Column header="" style={{ width: '45px' }} body={actionBtns}></Column>
                </DataTable>}
            </Dialog>}
        </React.Fragment>
    );
}

export default VariablesDialog;
