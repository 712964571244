import React, { useState, useEffect, useRef } from "react";
import { useEditor, EditorContent, FloatingMenu, BubbleMenu, EditorProvider, useCurrentEditor } from '@tiptap/react'
import EditorMenuBar from './EditorMenuBar'
import Bold from '@tiptap/extension-bold'
import Code from '@tiptap/extension-code'
import Document from '@tiptap/extension-document'
import Gapcursor from '@tiptap/extension-gapcursor'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Link from '@tiptap/extension-link'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Paragraph from '@tiptap/extension-paragraph'
import CodeBlock from './Nodes/CodeBlock'
// import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import History from '@tiptap/extension-history'
import Highlight from '@tiptap/extension-highlight'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import Blockquote from '@tiptap/extension-blockquote'
import HardBreak from '@tiptap/extension-hard-break'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Placeholder from '@tiptap/extension-placeholder'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import Heading from "@tiptap/extension-heading";
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
import IframeExtension from "./Nodes/Iframe";
import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import java from 'highlight.js/lib/languages/java'
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
import php from 'highlight.js/lib/languages/php'
import Resizable from "./Nodes/ImageResize";
import { all, common, createLowlight } from 'lowlight'

const lowlight = createLowlight()
lowlight.register({ java });
lowlight.register({ js });
lowlight.register({ css });
lowlight.register({ xml });
lowlight.register({ json });
lowlight.register({ php });

const CustomTable = Table.extend({
  renderHTML({ HTMLAttributes }) {
      return ['div', { class: 'tbl-responsive' }, ['table', HTMLAttributes, ['tbody', 0]]];
  },
});

function JEditor(props) {

  const extensions = [
    Document,
    Heading,
    Paragraph,
    Text,
    Code,
    Bold,
    Italic,
    Strike,
    History,
    OrderedList,
    BulletList,
    ListItem,
    Blockquote,
    CodeBlock.configure({
      lowlight,
    }),
    HardBreak,
    HorizontalRule,
    Placeholder.configure({
      placeholder: "Contenu …",
    }),
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    Link.configure({
      openOnClick: false,
      autolink: true,
    }),
    IframeExtension,
    Image.configure({
      inline: true,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph','image'],
    }),
    Resizable.configure({
      types: ["image", "video"], // resizable type
      handlerStyle: { // handler point style
        width: "8px",
        height: "8px",
        background: "#07c160",
      },
      layerStyle: { // layer mask style
        border: "2px solid #07c160",
      },
    }),
    Highlight.configure({ multicolor: true }),
    Gapcursor,
    TaskList,
    TaskItem.configure({
      nested: true,
    }),
  ];

  const updateContent = (e) => {
    if(props?.onTextChange) props.onTextChange(e.editor.getHTML());
  };


  return (
    <div className="Editor">
      {(
        <EditorProvider onUpdate={updateContent} slotBefore={<EditorMenuBar/>} extensions={extensions} content={props.content}>
        {/* <FloatingMenu>This is the floating menu</FloatingMenu> */}
        {/* <BubbleMenu>This is the bubble menu</BubbleMenu> */}
      </EditorProvider>
      )}
    </div>
  );
}

export default JEditor;
