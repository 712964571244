import React, { useState, useEffect, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import PlanDialog from './dialogs/edit'
import RelationsDialog from "./dialogs/relations";
import { appContext } from '../../../App'

function Plans() {
    const [plans, setPlans] = useState([]);
    const [search, debouncedSearch, setSearch] = useDebounce('', 2000);
    const [currentPlan, setCurrentPlan] = useState(undefined);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [relationsDialogVisible, setRelationsDialogVisible] = useState(false);
    const [relMode, setRelMode] = useState(false)
    const AppContext = useContext(appContext)
    const [filters, setFilters] = useState({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

    const loadPlans = () => {
        let params = [
            { key: 'join', value: 'plan_portal' },
            { key: 'join', value: 'form_plan' },
            { key: 'join', value: 'plan_step_template' },
            { key: 'include', value: 'plans.*,plan_portal.*,form_plan.*,plan_step_template.*' }
        ]
        if (search.length > 0) params.push({ key: "search", value: search });
        AppContext.api.getRecords("plans", params).then((res) => {
            setPlans(res.data.records);
        });
    }

    useEffect(() => {
        loadPlans()
    }, [debouncedSearch]);


    const endContent = (
        <React.Fragment>
            <Button
                label="Ajouter"
                icon="pi pi-check"
                severity="success"
                onClick={
                    () => {
                        setEditDialogVisible(true); setCurrentPlan({ name: '' })}
                }>
            </Button>
        </React.Fragment>
    );

    const showEditDialog = (item) => {
        setCurrentPlan(item)
        setEditDialogVisible(true)
    }

    const showRelationsDialog = (item, mode) => {
        setRelMode(mode)
        setCurrentPlan(item)
        setRelationsDialogVisible(true)
    }

    const actionBtns = (item) => {
        return (
            <React.Fragment>
                <span className="p-buttonset mr-2">
                    <Button
                        icon="pi pi-globe"
                        onClick={() => showRelationsDialog(item, 'portals')}
                        text raised
                        tooltip="Portails"
                    ></Button>
                    <Button
                        icon="pi pi-file"
                        onClick={() => showRelationsDialog(item, 'step_templates')}
                        text raised
                        tooltip="Modèles d'annonce"
                    ></Button>
                    <Button
                        icon="pi pi-check-square"
                        onClick={() => showRelationsDialog(item, 'forms_config')}
                        text raised
                        tooltip="Formulaires communs"
                    ></Button>
                </span>
                <span className="p-buttonset">
                    <Button
                        icon="pi pi-pencil"
                        onClick={() => showEditDialog(item)}
                        text raised
                    ></Button>
                    <Button
                        icon="pi pi-trash"
                        onClick={(event) => confirmRemovePlan(event, item)}
                        severity="danger"
                        text raised
                    ></Button>
                </span>
            </React.Fragment>
        );
    };

    const confirmRemovePlan = (event, plan) => {

        const accept = () => {
            AppContext.api.deleteRecords("plans", [plan.id]).then((res) => {
                loadPlans()
            });
        };

        confirmPopup({
            target: event.currentTarget,
            message: 'Voulez-vous supprimer ce plan?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            accept
        });
    };

    const savePlan = (plan) => {
        setEditDialogVisible(false)
        if (Object.hasOwn(plan, 'id')) {
            AppContext.api.putRecords("plans", [plan.id], plan).then((res) => {
                AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Plan modifié', life: 3000 });
                loadPlans()
            })
        }
        else {
            AppContext.api.postRecords("plans", plan).then((res) => {
                AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Plan ajouté', life: 3000 });
                loadPlans()
            })
        }
    }

    const cancelActionPlan = () => {
        setEditDialogVisible(false)
        setRelationsDialogVisible(false)
        setCurrentPlan(false)
    }

    const closeActionPlan = () => {
        loadPlans()
        setRelationsDialogVisible(false)
        setCurrentPlan(false)
    }

    return (
        <React.Fragment>
            <ConfirmPopup />
            <PlanDialog
                visible={editDialogVisible}
                plan={currentPlan}
                onSave={savePlan}
                onCancel={cancelActionPlan} />
            <RelationsDialog
                visible={relationsDialogVisible}
                plan={currentPlan}
                mode={relMode}
                onSave={closeActionPlan}
                onCancel={cancelActionPlan} />
            <Card
                className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
                header={
                    <Toolbar
                        start={<h2 className="m-0">Plans</h2>}
                        end={endContent}
                        className="shadow-none border-bottom-1 surface-border border-noround-bottom"
                    />
                }
            >
                <DataTable
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    value={plans}
                    tableStyle={{ minWidth: "50rem" }}
                    scrollable
                    scrollHeight="flex"
                    className="fullHeightTable"
                    sortMode="multiple"
                    removableSort 
                    globalFilterFields={['name']}
                    filters={filters}
                >
                    <Column field="name" header="Nom" sortable filter></Column>
                    <Column header="" body={actionBtns} style={{ width: '200px' }}></Column>
                </DataTable>
            </Card>
        </React.Fragment>
    );
}

export default Plans;
