import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { appContext } from '../../../../App'

function PlanDialog(props) {
    const [currentPlan, setCurrenPlan] = useState(undefined);
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)

    useEffect(() => {
        setVisible(props.visible)
        props.visible ? setCurrenPlan(props.plan) : setCurrenPlan(false)
    }, [props.visible]);

    const closeDialog = () => {
        props.onCancel();
        setCurrenPlan(undefined)
        setVisible(false)
    }

    const savePlan = () => {
        let errors = {}
        if (currentPlan.name == '') errors['name'] = 'Champs "Nom" requis.'

        if (Object.keys(errors).length > 0) {
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({
                severity: 'error',
                summary: 'Erreurs',
                detail: msg.map((m, i) => <div key={i}>{m}</div>),
                life: 3000
            });
            return;
        }

        props.onSave(currentPlan);
        setCurrenPlan(undefined)
        setVisible(false)
    }

    const footerContent = (
        <div>
            <Button
                label="Annuler"
                icon="pi pi-times"
                onClick={closeDialog}
                className="p-button-text" />
            <Button
                label={currentPlan?.id ? "Modifier" : "Ajouter"}
                icon="pi pi-check"
                disabled={currentPlan?.name && currentPlan.name.length == 0}
                onClick={savePlan} className="mr-0" />
        </div>
    );

    return (
        <React.Fragment>
            {currentPlan && 
            <Dialog 
                header="Plan" 
                footer={footerContent} 
                visible={isVisible} 
                style={{ width: '300px' }} 
                onHide={closeDialog}>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="planLabel">Nom</label>
                    <InputText 
                        id="planLabel" 
                        value={currentPlan.name} 
                        onChange={(e) => setCurrenPlan({...currentPlan, name:e.target.value})} 
                        placeholder="Nom du plan" 
                        className="mr-2" />
                </div>
            </Dialog>}
        </React.Fragment>
    );
}

export default PlanDialog;
