import React, { useState, useEffect, useRef, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import API from "../../../api";
import PermissionDialog from './permissionDialog'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { appContext } from '../../../App'

FilterService.register('custom_user_id', (value, filters) => {
  if(!filters) return true
  if(value?.email)
    return value.email.includes(filters)
  return true
});

function Permissions() {
  const [permissions, setPermissions] = useState([]);
  const [currentPermission, setCurrentPermission] = useState(undefined);
  const AppContext = useContext(appContext)
  const [filters, setFilters] = useState({
    user_id: { value: null, matchMode: FilterMatchMode.CUSTOM },
});

  const loadPermissions = () => {
    let params = [
      { key: "join", value: "users" },
      { key: "include", value: "permissions.*,users.email" }
    ]
    AppContext.api.getRecords("permissions", params).then((res) => {
      setPermissions(res.data.records);
    });
  }

  useEffect(() => {
    loadPermissions()
  }, []);

  const startContent = (
    <h2 className="m-0">Permissions</h2>
  );

  const endContent = (
    <React.Fragment>
      <Button label="Ajouter" icon="pi pi-check" severity="success" onClick={() => addPermission()}></Button>
    </React.Fragment>
  );

  const addPermission = () => {
    let perm = {}
    setCurrentPermission(perm)
  }

  const actionBtns = (permission) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            icon="pi pi-pencil"
            onClick={() => setCurrentPermission(permission)}
            text raised
          ></Button>
          <Button
            icon="pi pi-trash"
            onClick={(event) => confirmRemovePermission(event, permission)}
            severity="danger"
            text raised
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const savePermission = (permission) => {
    if (Object.hasOwn(permission, 'id')) {
      delete permission.user_id;
      AppContext.api.putRecords("permissions", [permission.id], permission).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Permission sauvegardée', life: 3000 });
        loadPermissions()
      })
    }
    else {
      AppContext.api.postRecords("permissions", permission).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Permission sauvegardée', life: 3000 });
        loadPermissions()
      })
    }
  }

  const cancelPermission = () => {
    setCurrentPermission(undefined)
  }


  const userTemplate = (permission) => {
    return (permission?.user_id?.email) ? permission.user_id.email : '-'
  }
  const permissionTemplate = (permission, droit) => {
    return permission[droit] ? 'Oui' : 'Non'
  }

  const confirmRemovePermission = (event, permission) => {

    const accept = () => {
      API.deleteRecords("permissions", [permission.id]).then((res) => {
        loadPermissions()
      });
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer cette permission?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  return (
    <React.Fragment>
      <ConfirmPopup />
      <PermissionDialog permission={currentPermission} onSave={savePermission} onCancel={cancelPermission} />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <DataTable
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={permissions}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          scrollHeight="flex"
          className="fullHeightTable"
          sortMode="multiple"
          removableSort
          globalFilterFields={['user_id']}
          filters={filters}
        >
          <Column field="user_id" header="Email" showFilterMatchModes={false}  filter sortable body={userTemplate}></Column>
          <Column header="Portail" field="portal" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'portal')}></Column>
          <Column header="Annonces" field="steps" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'steps')}></Column>
          <Column header="Formulaires" field="forms" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'forms')}></Column>
          <Column header="Codes" field="codes" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'codes')}></Column>
          <Column header="Catégories" field="categories" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'categories')}></Column>
          <Column header="Permissions" field="permissions" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'permissions')}></Column>
          <Column header="Soumissions" field="submissions" style={{ width: '85px' }} body={(permission) => permissionTemplate(permission, 'submissions')}></Column>
          <Column header="" style={{ width: '85px' }} body={actionBtns}></Column>
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default Permissions;
