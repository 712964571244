import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useEditor, EditorContent, FloatingMenu, BubbleMenu, EditorProvider, useCurrentEditor } from '@tiptap/react'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import VariablesDialog from "./VariablesDialog";
import { appContext } from '../../../App'
import { Tooltip } from "primereact/tooltip";

function MenuBar(props) {
    const { editor } = useCurrentEditor();
    const [selectedType, setSelectedType] = useState('paragraph')
    const [variablesDialogVisible, setVariablesDialogVisible] = useState(false)
    const tableMenu = useRef(null);
    const highlightMenu = useRef(null);
    const AppContext = useContext(appContext)

    const blockTypes = [
        'paragraph',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
    ]

    const setBlockType = (type) => {
        switch (type) {
            case 'paragraph':
                editor.chain().focus().setParagraph().run()
                break;
            case 'h1':
                editor.chain().focus().toggleHeading({ level: 1 }).run()
                break;
            case 'h2':
                editor.chain().focus().toggleHeading({ level: 2 }).run()
                break;
            case 'h3':
                editor.chain().focus().toggleHeading({ level: 3 }).run()
                break;
            case 'h4':
                editor.chain().focus().toggleHeading({ level: 4 }).run()
                break;
            case 'h5':
                editor.chain().focus().toggleHeading({ level: 5 }).run()
                break;
            case 'h6':
                editor.chain().focus().toggleHeading({ level: 6 }).run()
                break;
        }
    }

    const checkBlockType = () => {
        if (editor.isActive('paragraph')) {
            setSelectedType('paragraph')
        }
        if (editor.isActive('heading', { level: 1 })) {
            setSelectedType('h1')
        }
        if (editor.isActive('heading', { level: 2 })) {
            setSelectedType('h2')
        }
        if (editor.isActive('heading', { level: 3 })) {
            setSelectedType('h3')
        }
        if (editor.isActive('heading', { level: 4 })) {
            setSelectedType('h4')
        }
        if (editor.isActive('heading', { level: 5 })) {
            setSelectedType('h5')
        }
        if (editor.isActive('heading', { level: 6 })) {
            setSelectedType('h6')
        }
    }

    useEffect(() => {
        editor.on('selectionUpdate', ({ editor, event }) => {
            checkBlockType()
        })
        editor.on('update', ({ editor, event }) => {
            checkBlockType()
        })
    }, [])

    const setLink = useCallback(() => {

        AppContext.pickMedia((url) => {
            if(url === ''){
                editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()
                return
            }
            editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run()
        }, () => { })
        
    }, [editor])

    const setImage = useCallback(() => {
        
        AppContext.pickMedia((url) => editor.chain().focus().setImage({ src: url }).run(), () => { })
    
      }, [editor])

    const highlightItems = [
        {
            label: 'Orange',
            command: () => editor.chain().focus().toggleHighlight({ color: '#ffc078' }).run(),
            // disabled: editor.isActive('highlight')
        },
        {
            label: 'Vert',
            command: () => editor.chain().focus().toggleHighlight({ color: '#8ce99a' }).run(),
            // disabled: editor.isActive('highlight')
        },
        {
            label: 'Bleu',
            command: () => editor.chain().focus().toggleHighlight({ color: '#74c0fc' }).run(),
            // disabled: editor.isActive('highlight')
        },
        {
            label: 'Violet',
            command: () => editor.chain().focus().toggleHighlight({ color: '#b197fc' }).run(),
            // disabled: editor.isActive('highlight')
        },
        {
            label: 'Rouge',
            command: () => editor.chain().focus().toggleHighlight({ color: '#ffa8a8' }).run(),
            // disabled: editor.isActive('highlight')
        },
        {
            label: 'Supprimer',
            command: () => editor.chain().focus().unsetHighlight().run(),
            disabled: !editor.isActive('highlight')
        }
    ]

    const items = [
        {
            label: 'Colonnes',
            items: [
                {
                    label: 'Ajouter avant',
                    command: () => editor.chain().focus().addColumnBefore().run(),
                    disabled: !editor.can().addColumnBefore()
                },
                {
                    label: 'Ajouter après',
                    command: () => editor.chain().focus().addColumnAfter().run(),
                    disabled: !editor.can().addColumnAfter()
                },
                {
                    label: 'Supprimer',
                    command: () => editor.chain().focus().deleteColumn().run(),
                    disabled: !editor.can().deleteColumn()
                },
                {
                    label: 'Basculer entête',
                    command: () => editor.chain().focus().toggleHeaderColumn().run(),
                    disabled: !editor.can().toggleHeaderColumn()
                }
            ]
        },
        {
            label: 'Lignes',
            items: [
                {
                    label: 'Ajouter avant',
                    command: () => editor.chain().focus().addRowBefore().run(),
                    disabled: !editor.can().addRowBefore()
                },
                {
                    label: 'Ajouter après',
                    command: () => editor.chain().focus().addRowAfter().run(),
                    disabled: !editor.can().addRowAfter()
                },
                {
                    label: 'Supprimer',
                    command: () => editor.chain().focus().deleteRow().run(),
                    disabled: !editor.can().deleteRow()
                },
                {
                    label: 'Basculer entête',
                    command: () => editor.chain().focus().toggleHeaderRow().run(),
                    disabled: !editor.can().toggleHeaderRow()
                }
            ]
        },
        {
            label: 'Cellules',
            items: [
                {
                    label: 'Fusionner',
                    command: () => editor.chain().focus().mergeCells().run(),
                    disabled: !editor.can().mergeCells()
                },
                {
                    label: 'Diviser',
                    command: () => editor.chain().focus().splitCell().run(),
                    disabled: !editor.can().splitCell()
                },
                {
                    label: 'Fusionner ou diviser',
                    command: () => editor.chain().focus().mergeOrSplit().run(),
                    disabled: !editor.can().mergeOrSplit()
                },
                {
                    label: 'Basculer entête',
                    command: () => editor.chain().focus().toggleHeaderCell().run(),
                    disabled: !editor.can().toggleHeaderCell()
                }
            ]
        },
        {
            label: 'Réparer le tableau',
            command: () => editor.chain().focus().fixTables().run(),
            disabled: !editor.can().fixTables()
        }
    ];

    if (!editor) {
        return null
    }

    const loadVariables = () => {
        setVariablesDialogVisible(true)
    }

    const insertVariable = (variable) => {
        editor.commands.insertContent('%' + variable.name + '%')
        setVariablesDialogVisible(false)
    }

    const setIframe = () => {
        AppContext.pickMedia((url) => editor.chain().focus().setIframe({ src: url }).run(), () => { })
        
    }

    return (
        <div className="EditorMenuBar">
            <VariablesDialog visible={variablesDialogVisible} onInsert={insertVariable} onClose={() => setVariablesDialogVisible(false)} />
            <span className="p-buttonset" style={{float:'left'}}>
            <Dropdown className="blockType" value={selectedType} onChange={(e) => setBlockType(e.value)} options={blockTypes} size="small"
                placeholder="Selectionner un type" />
                </span>
            <span className="p-buttonset">
                <Button
                    text={!editor.isActive('bold')}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                    }
                    className={editor.isActive('bold') ? 'is-active' : ''}
                    icon="icon-bold"
                    aria-label="bold"
                    severity="plain"
                    tooltip="Gras"
                >
                </Button>
                <Button
                    text={!editor.isActive('italic')}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    }
                    className={editor.isActive('italic') ? 'is-active' : ''}
                    icon="icon-italic"
                    aria-label="italic"
                    tooltip="Italic"
                >
                </Button>
            </span>
            <span className="p-buttonset">
                <Button
                    text={!editor.isActive('link')}
                    onClick={setLink}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()
                    }
                    className={editor.isActive('link') ? 'is-active' : ''}
                    icon="icon-chain"
                    aria-label="link"
                    tooltip="Lien"
                >
                </Button>
                <Button
                    text
                    onClick={() => editor.chain().focus().unsetLink().run()}
                    disabled={
                        !editor.isActive('link')
                    }
                    className={editor.isActive('link') ? 'is-active' : ''}
                    icon="icon-chain-broken"
                    aria-label="link"
                    tooltip="Supprimer le lien"
                >
                </Button>
            </span>
            <span className="p-buttonset">
                <Button
                    text={!editor.isActive('iframe')}
                    onClick={setIframe}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()
                    }
                    className={editor.isActive('iframe') ? 'is-active' : ''}
                    icon="icon-file-pdf"
                    aria-label="link"
                    tooltip="Iframe"
                >
                </Button>
                <Button
                    text={!editor.isActive('image')}
                    onClick={setImage}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()
                    }
                    className={editor.isActive('image') ? 'is-active' : ''}
                    icon="icon-image"
                    aria-label="link"
                    tooltip="Image"
                >
                </Button>
            </span>
            <span className="p-buttonset">
                <Button
                    text
                    onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
                    className={editor.isActive('table') ? 'is-active' : ''}
                    icon="icon-table"
                    aria-label="Table"
                    tooltip="Tableau"
                >
                </Button>
                <TieredMenu model={items} popup ref={tableMenu} />
                <Button onClick={(e) => tableMenu.current.toggle(e)} icon="pi pi-angle-down" text />
            </span>
            <span className="p-buttonset">
                <Button
                    text
                    onClick={() => editor.chain().focus().toggleHighlight().run()}
                    className={editor.isActive('highlight') ? 'is-active' : ''}
                    icon="icon-pencil"
                    aria-label="Highlight"
                    tooltip="Surbrillance"
                >
                </Button>
                <TieredMenu model={highlightItems} popup ref={highlightMenu} />
                <Button onClick={(e) => highlightMenu.current.toggle(e)} icon="pi pi-angle-down" text />
            </span>
            <span className="p-buttonset">
            <Button
                text
                onClick={() => editor.chain().focus().toggleCode().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleCode()
                        .run()
                }
                className={editor.isActive('code') ? 'is-active' : ''}
                icon="icon-embed"
                aria-label="code"
                tooltip="Code"
            >
            </Button>
            </span>
            <span className="p-buttonset">
            <Button text onClick={() => editor.chain().focus().unsetAllMarks().run()}
                icon="icon-clear-formatting"
                aria-label="clear marks"
                tooltip="Supprimer le formatage">
            </Button>
            </span>
            <span className="p-buttonset">
            <Button text onClick={() => editor.chain().focus().clearNodes().run()}
                icon=" icon-cancel-circle"
                aria-label="clear nodes"
                tooltip="Supprimer les blocks">
            </Button>
            </span>
            <span className="p-buttonset">
                <Button
                    text={!editor.isActive('bulletList')}
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                    icon="icon-list2"
                    aria-label="bullet-list"
                    tooltip="Liste à puce"
                ></Button>
                <Button
                    text={!editor.isActive('orderedList')}
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                    icon="icon-list-numbered"
                    aria-label="ordered list"
                    tooltip="Liste ordonnée"
                >
                </Button>
                <Button
                    text={!editor.isActive('taskList')}
                    onClick={() => editor.chain().focus().toggleTaskList().run()}
                    className={editor.isActive('taskList') ? 'is-active' : ''}
                    icon="icon-checkbox-checked1"
                    aria-label="Task list"
                    tooltip="Liste de tâches"
                >
                </Button>
            </span>
            <span className="p-buttonset">
            <Button
                text={!editor.isActive('codeBlock')}
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor.isActive('codeBlock') ? 'is-active' : ''}
                icon="icon-embed2"
                aria-label="code block"
                tooltip="Bloc de code"
            >
            </Button>
            </span>
            <span className="p-buttonset">
            <Button
                text={!editor.isActive('blockquote')}
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
                icon="icon-quotes-left"
                aria-label="blockquote"
                tooltip="Citation"
            >
            </Button>
            </span>
            <span className="p-buttonset">
                <Button text onClick={() => editor.chain().focus().setHorizontalRule().run()}
                    icon="icon-minus"
                    aria-label="horizontal rule"
                    tooltip="Séparateur horizontal">
                </Button>
                {/* <Button text onClick={() => editor.chain().focus().setHardBreak().run()}
                    icon="icon-pagebreak"
                    aria-label="hard break">
                </Button> */}
            </span>
            
            <span className="p-buttonset">
                <Button text onClick={() => editor.chain().focus().setTextAlign('left').run()}
                    icon="icon-paragraph-left"
                    aria-label="Aligner à gauche"
                    tooltip="Aligner à gauche"
                    disabled={editor.isActive({ textAlign: 'left' })}
                    className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}>
                </Button>
                <Button text onClick={() => editor.chain().focus().setTextAlign('center').run()}
                    icon="icon-paragraph-center"
                    aria-label="Aligner au centre"
                    tooltip="Aligner au centre"
                    disabled={editor.isActive({ textAlign: 'center' })}
                    className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>
                </Button>
                <Button text onClick={() => editor.chain().focus().setTextAlign('right').run()}
                    icon="icon-paragraph-right"
                    aria-label="Aligner à droite"
                    tooltip="Aligner à droite"
                    disabled={editor.isActive({ textAlign: 'right' })}
                    className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>
                </Button>
                <Button text onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                    icon="icon-paragraph-justify"
                    aria-label="Justifer"
                    tooltip="Justifer"
                    disabled={editor.isActive({ textAlign: 'justify' })}
                    className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}>
                </Button>
            </span>
            <span className="p-buttonset">
            <Button
                text
                onClick={() => loadVariables()}
                icon="pi pi-sliders-h"
                aria-label="Variables"
                tooltip="Variables"
            ></Button>
            </span>
            <span className="p-buttonset">
                <Button
                    text
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .undo()
                            .run()
                    }
                    icon="pi pi-replay"
                    aria-label="undo"
                    tooltip="Annuler"
                ></Button>
                <Button
                    text
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .redo()
                            .run()
                    }
                    icon="pi pi-refresh"
                    aria-label="redo"
                    tooltip="Rétablir"
                ></Button>
            </span>
        </div>
    )
}

export default MenuBar;
