import React, { useEffect, useState, createContext, useRef } from "react";
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import logo from "./logo.svg";
import "./App.scss";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import Layout from "./components/Layout";
import DashboardAdmin from "./components/pages/dashboard/admin";
import DashboardConfig from "./components/pages/dashboard/config";
import DashboardUser from "./components/pages/dashboard/user";
import MyProfile from "./components/pages/my/profile";
import MyHelp from "./components/pages/my/help"
import Steps from "./components/pages/steps";
import Forms from "./components/pages/forms";
import Submissions from "./components/pages/submissions";
import SubmissionView from "./components/pages/submissions/view";
import Medias from "./components/pages/medias";
import Categories from "./components/pages/categories";
import Codes from "./components/pages/codes";
import CodeEdit from "./components/pages/codes/edit";
import Permissions from "./components/pages/permissions";
import Portals from "./components/pages/portals";
import PortalEdit from "./components/pages/portals/edit";
import StepEdit from "./components/pages/steps/edit";
import FormEdit from "./components/pages/forms/edit";
import Users from "./components/pages/users";
import UserEdit from "./components/pages/users/edit";
import Steptemplates from "./components/pages/steptemplates";
import Plans from "./components/pages/plans";
import Settings from "./components/pages/settings";
import Login from "./components/pages/users/login"
import { Toast } from 'primereact/toast';
import MediasPicker from "./components/layout/media/MediaPicker";
import API from "./api";
import moment from "moment";
import locales from "./locales/primereact.json"
import momentLocales from "./locales/momentjs"
import Errors from "./components/pages/errors"
import '../node_modules/font-awesome/css/font-awesome.min.css';

addLocale('fr', locales.fr);
locale('fr');
moment.locale('fr', momentLocales);

const appContext = createContext();

let mediaCallbackOK = false;
let mediaCallbackCancel = false;


function App() {
  const [isLoading, setIsLoading] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [router, setRouter] = useState(false)
  const toast = useRef(null);
  const [selectMediaPicker, setSelectMediaPicker] = useState(false);
  const [pickerOptions, setPickerOptions] = useState({});

  const pickMedia = (clbkOk, clbkCancel, options=false) => {
    setSelectMediaPicker(true)
    if(options) setPickerOptions(options)
    mediaCallbackOK = clbkOk
    mediaCallbackCancel = clbkCancel
  }

  const selectMedia = (media) => {
    mediaCallbackOK(media)
    setSelectMediaPicker(false)
    setPickerOptions({})
    mediaCallbackOK = false;
    mediaCallbackCancel = false;
  }

  const cancelMedia = () => {
    mediaCallbackCancel()
    setSelectMediaPicker(false)
    setPickerOptions({})
    mediaCallbackOK = false;
    mediaCallbackCancel = false;
  }

  const showNotification = (data) =>{
    toast.current.show(data);
  }

  useEffect(() => {
    API.getInstance().interceptors.request.use(function (config) {
      setIsLoading(true)
      return config
    }, function (error) {
      setIsLoading(false)
      showNotification({ severity: 'error', summary: 'Erreur', detail: "Impossible d'appler le service : "+error.config.url, life: 3000 })
      // return true;
      return Promise.reject(error);
    });
  
    API.getInstance().interceptors.response.use(function (response) {
      setIsLoading(false)
      return response;
    }, function (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 401) {
        window.location.href = '/login'
      }
      else if (error.response && error.response.status === 403) {
        let message = error?.response?.data?.message ? error.response.data.message : 'Impossible de se connecter';
        showNotification({ severity: 'error', summary: 'Erreur', detail: message, life: 3000 })
      }
      else{
        showNotification({ severity: 'error', summary: 'Erreur', detail: "Impossible d'appler le service : "+error.config.url, life: 3000 })
      }
      // return true;
      return Promise.reject(error);
    });
  },[])


  useEffect(() => {
    const r = createBrowserRouter([
      {
        path: "/login",
        element: <Login />,
        id: "login"
      },
      {
        path: "/",
        id: 'root',
        loader: async ({ params }) => {
          let res = await API.get("user/me");
          return (res?.data) ? res.data : false
        },
        errorElement: <Errors/>,
        children: [
          {
            path: "/",
            element: <Layout mode="user" />,
            id: "user",
            children: [{
              path: '/',
              id: 'dashboard',
              element: <DashboardUser />,
            }, {
              path: '/me',
              id: 'me',
              element: <MyProfile />,
            }, {
              path: '/help',
              id: 'help',
              element: <MyHelp />,
            }]
          },
          {
            path: "/config",
            element: <Layout mode="config" />,
            id: "config",
            errorElement: <Errors/>,
            loader: ({ params }) => {
              delete API.getInstance().defaults.headers.common['X-PORTAL']
              return true;
            },
            children: [
              {
                path: "/config",
                element: <DashboardConfig />,
              },
              {
                path: "/config/portals",
                element: <Portals />,
              },
              {
                path: "/config/portals/create",
                element: <PortalEdit mode="create" />,
              },
              {
                path: "/config/portals/:portalId/edit",
                element: <PortalEdit />,
              },
              {
                path: "/config/users",
                element: <Users />,
              },
              {
                path: "/config/users/create",
                element: <UserEdit mode="create" />,
              },
              {
                path: "/config/users/:userId/edit",
                element: <UserEdit />,
              },
              {
                path: "/config/step_templates",
                element: <Steptemplates />,
              },
              {
                path: "/config/step_templates/create",
                element: <StepEdit mode="create" type="template" />,
              },
              {
                path: "/config/step_templates/:steptemplateId/edit",
                element: <StepEdit type="template" />,
              },
              {
                path: "/config/forms",
                element: <Forms type="template" />,
              },
              {
                path: "/config/forms/:formId/create",
                element: <FormEdit mode="create" type="template" />,
              },
              {
                path: "/config/forms/:formId/edit",
                element: <FormEdit type="template" />,
              },
              {
                path: "/config/medias",
                element: <Medias />,
              },
              {
                path: "/config/plans",
                element: <Plans />,
              },
              {
                path: "/config/settings",
                element: <Settings />,
              },
            ]
          },
          {
            path: "/portal",
            element: <Layout mode="portal" />,
            id: "portal",
            errorElement: <Errors/>,
            loader: ({ params }) => {
              API.getInstance().defaults.headers.common['X-PORTAL'] = params.portalId
              return params.portalId;
            },
            children: [
              {
                path: "/portal/:portalId",
                element: <DashboardAdmin />,
              },
              {
                path: "/portal/:portalId/steps",
                element: <Steps />,
              },
              {
                path: "/portal/:portalId/steps/create",
                element: <StepEdit mode="create" />,
              },
              {
                path: "/portal/:portalId/steps/:stepId/edit",
                element: <StepEdit />,
              },
              {
                path: "/portal/:portalId/forms",
                element: <Forms />,
              },
              {
                path: "/portal/:portalId/forms/create",
                element: <FormEdit mode="create" />,
              },
              {
                path: "/portal/:portalId/forms/:formId/edit",
                element: <FormEdit />,
              },
              {
                path: "/portal/:portalId/submissions",
                element: <Submissions />,
              },
              {
                path: "/portal/:portalId/submissions/:submissionId/view",
                element: <SubmissionView />,
              },
              {
                path: "/portal/:portalId/medias",
                element: <Medias />,
              },
              {
                path: "/portal/:portalId/categories",
                element: <Categories />,
              },
              {
                path: "/portal/:portalId/codes",
                element: <Codes />,
              },
              {
                path: "/portal/:portalId/codes/create",
                element: <CodeEdit mode="create" />,
              },
              {
                path: "/portal/:portalId/codes/:codeId/edit",
                element: <CodeEdit />,
              },
              {
                path: "/portal/:portalId/permissions",
                element: <Permissions />,
              },
              {
                path: "/portal/:portalId/edit",
                element: <PortalEdit />,
              }
            ]
          },
        ]
      },
    ], { basename: process.env.PUBLIC_URL });
    setRouter(r)
  }, [])

  return (
    <>
      <appContext.Provider value={{ api: API, showNotification, pickMedia }}>
        <MediasPicker visible={selectMediaPicker} onCancel={cancelMedia} onSelect={selectMedia} options={pickerOptions}/>
        <Toast ref={toast} position="bottom-right" />
        {isLoading && <div className="globalSpinner">
          <ProgressSpinner className="spinner" />
        </div>}
        {router && <RouterProvider router={router} />}
      </appContext.Provider>
    </>
  );
}

export default App;
export {appContext}
