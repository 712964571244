import React, { useState, useEffect, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { appContext } from '../../../App'
import { getPathByName } from "../../../routesUtils"
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';

FilterService.register('custom_tags', (value, filters) => {
    if (!filters) return true
    return value.includes(filters)
});

FilterService.register('custom_categories', (value, filters) => {
    if (!filters) return true
    return value.includes(filters)
});

function Steptemplates() {
    const navigate = useNavigate();
    const [step_templates, setSteptemplates] = useState([]);
    const [search, debouncedSearch, setSearch] = useDebounce('', 2000);
    let { portalId } = useParams(null);
    const AppContext = useContext(appContext)
    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        section: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        tags: { value: null, matchMode: FilterMatchMode.CUSTOM },
        categories: { value: null, matchMode: FilterMatchMode.CUSTOM },
        enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const [filterTags, setFilterTags] = useState([])
    const [filterCats, setFilterCats] = useState([])

    const loadStepTemplates = () => {
        let fields = [
            'step_templates.id',
            'step_templates.name',
            'step_templates.section',
            'step_templates.tags',
            'step_templates.categories',
            'step_templates.links',
            'step_templates.enabled'
        ]
        let params = [{key:'include',value:fields.join(',')}]
        if (search.length > 0) params.push({ key: "search", value: search });
        AppContext.api.getRecords("step_templates", params).then((res) => {
            setSteptemplates(res.data.records);
            if (res.data?.records) {
                let catsT = [];
                let tagsT = [];
                res.data.records.map((r) => {
                    catsT = catsT.concat(r.categories);
                    tagsT = tagsT.concat(r.tags);
                })
                catsT = catsT.filter((value, index, array) => {
                    return array.indexOf(value) === index;
                })
                setFilterCats(catsT)
                
                tagsT = tagsT.filter((value, index, array) => {
                    return array.indexOf(value) === index;
                })
                setFilterTags(tagsT)
            }
        });
    }

    useEffect(() => {
        loadStepTemplates()
    }, [debouncedSearch]);

    const startContent = (
        <React.Fragment>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    value={search}
                    placeholder="Rechercher"
                    onChange={(e) => setSearch(e.target.value)} />
            </span>
        </React.Fragment>
    );

    const centerContent = (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
                value={search}
                placeholder="Rechercher"
                onChange={(e) => setSearch(e.target.value)} />
        </span>
    );

    const endContent = (
        <React.Fragment>
            <span className="p-buttonset">
                <Button
                    label="Ajouter"
                    icon="pi pi-check"
                    severity="success"
                    onClick={() => navigate(getPathByName('configCreateSteptemplate'))}></Button>
            </span>
        </React.Fragment>
    );

    const actionBtns = (item) => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button
                        icon="pi pi-pencil"
                        onClick={() => navigate(getPathByName('configEditSteptemplate', { steptemplateId: item.id }))}
                        text raised
                    ></Button>
                    <Button
                        icon="pi pi-trash"
                        onClick={(event) => confirmRemoveSteptemplate(event, item)}
                        severity="danger"
                        text raised
                    ></Button>
                </span>
            </React.Fragment>
        );
    };

    const confirmRemoveSteptemplate = (event, steptemplate) => {

        const accept = () => {
            AppContext.api.deleteRecords("step_templates", [steptemplate.id]).then((res) => {
                loadStepTemplates()
            });
        };

        confirmPopup({
            target: event.currentTarget,
            message: 'Voulez-vous supprimer ce modèle d\'annonce?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            accept
        });
    };

    const tags = (item) => {
        return (
            <React.Fragment>
                {item.tags.map((tag, index) => {
                    return <Tag key={index}>
                        <div className="flex align-items-center gap-2">
                            <span className="text-base">{tag}</span>
                        </div>
                    </Tag>;
                })}
            </React.Fragment>
        );
    };

    const categories = (item) => {
        return (
            <React.Fragment>
                {item.categories.map((category, index) => {
                    return (
                        category ? <Tag key={index} >
                            <div className="flex align-items-center gap-2">
                                <span className="text-base">{category}</span>
                            </div>
                        </Tag> : null
                    );
                })}
            </React.Fragment>
        );
    };

    const links = (item) => {
        const links = [];
        item.links.map((link, index) => {
            links.push(link.type);
        }); const uniqueLinks = links.filter((v, i, a) => a.indexOf(v) === i);
        return (
            <React.Fragment>
                {uniqueLinks.map((link, index) => {
                    return (
                        <Tag key={index}>
                            <div className="flex align-items-center gap-2">
                                <span className="text-base">{link}</span>
                            </div>
                        </Tag>
                    );
                })}
            </React.Fragment>
        );
    };

    const tagsRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={filterTags}
            onChange={(e) =>
                options.filterApplyCallback(e.value)}
            placeholder="Selectionner un tag"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }} />
    };

    const categoriesRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={filterCats}
            onChange={(e) =>
                options.filterApplyCallback(e.value)}
            placeholder="Selectionner une catégorie"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }} />
    };

    const enabledBody = (field) => {
        return field.enabled ? <strong>Activé</strong> : <span>Désactivé</span>
    };

    const enabledRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={[{ value: true, label: 'Activé' }, { value: false, label: 'Désactivé' }]}
            onChange={(e) =>
                options.filterApplyCallback(e.value)}
            placeholder="Statut"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }} />
    };

    return (
        <React.Fragment>
            <ConfirmPopup />
            <Card
                className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
                header={
                    <Toolbar
                        start={startContent}
                        end={endContent}
                        className="shadow-none border-bottom-1 surface-border border-noround-bottom"
                    />
                }
            >
                <DataTable
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    value={step_templates}
                    tableStyle={{ minWidth: "50rem" }}
                    scrollable
                    scrollHeight="flex"
                    className="fullHeightTable"
                    sortMode="multiple"
                    removableSort
                    globalFilterFields={['name', 'section', 'tags', 'categories']}
                    filters={filters}
                >
                    <Column 
                        field="name" 
                        header="Name"
                        sortable
                        filter ></Column>
                    <Column 
                        field="section" 
                        header="Section"
                        sortable
                        filter ></Column>
                    <Column 
                        header="Tags" 
                        body={tags}
                        filter
                        filterElement={tagsRowFilterTemplate}
                        showFilterMatchModes={false}
                        field="tags"
                    ></Column>
                    <Column 
                        header="Catégories" 
                        body={categories}
                        filter
                        filterElement={categoriesRowFilterTemplate}
                        showFilterMatchModes={false}
                        field="categories"
                    ></Column>
                    <Column 
                        header="Liens" 
                        body={links}
                    ></Column>
                    <Column field="enabled" header="Statut" body={enabledBody}
                        dataType="boolean"
                        filter
                        filterElement={enabledRowFilterTemplate}
                        sortable></Column>
                    <Column 
                        header="" 
                        body={actionBtns} 
                        style={{ width: '85px' }}></Column>
                </DataTable>
            </Card>
        </React.Fragment>
    );
}

export default Steptemplates;
