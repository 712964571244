import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { Panel } from 'primereact/panel';
import { getPathByName } from "../../../routesUtils"
import {appContext} from '../../../App'

function DashboardUser() {
    const navigate = useNavigate();
    const [portals, setPortals] = useState([]);
    const [infos, setInfos] = useState([]);
    let { portalId } = useParams(null);
    const my = useRouteLoaderData('root');
    const AppContext = useContext(appContext)

    const loadDashboard = () => {
        let params = [
        ]
        AppContext.api.get("/dashboard/user", params).then((res) => {
            let portalsMenu = []

            if (res?.data?.portals) {
                res.data.portals.map((portal) => {
                    portalsMenu.push({
                        label: portal.name,
                        template: (item,index) => {
                            return (<div className='p-menuitem-content' key={index}>
                                <a className="flex align-items-center p-menuitem-link" onClick={() => navigate(getPathByName('portalDashboard', { portalId: portal.id }))}>
                                    {portal.name}
                                </a>
                            </div>)
                        }
                    })
                })
            }

            if (res?.data?.infos) setInfos(res.data.infos);

            setPortals(portalsMenu);
        });
    }

    useEffect(() => {
        loadDashboard()
    }, []);

    const headerTemplate = (options, title, btns) => {
        const className = `${options.className} justify-content-space-between`;
    
        return (
          <div className={className}>
            <div className="flex align-items-center gap-2">
              <span className="font-bold">{title}</span>
            </div>
            {btns && btns.length > 0 && <div className="flex">
              {btns.map((item, index) => <Button key={index} label={item.label} text raised size="small" onClick={item.onclick}/>)}
            </div>}
          </div>
        );
      };
    
      const panelPt = {
        root:{className: 'col shadow-none border-1 surface-border flex flex-column flex-grow-1 p-0'},
        toggleableContent: {className: 'flex-grow-1 flex', style:{'height':0}},
        content: { className: 'flex-grow-1 overflow-y' },
      }

    return (
        <div className="flex flex-column">
            <div className="grid flex-grow-1 p-2">
                <Panel
                    header={'Bonjour ' + my.user.name}
                    pt={panelPt}
                    className="col-6 mr-2">
                    <div dangerouslySetInnerHTML={{__html: infos}} />
                </Panel>
                <Panel
                    header={'Portails'}
                    pt={panelPt}
                    className="col ml-2">
                    <Menu model={portals} className="w-full border-0" />
                </Panel>
            </div>
        </div>
    )
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default DashboardUser;
