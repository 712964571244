import React, { useState, useEffect, useRef, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CategoryDialog from './categoryDialog'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import {appContext} from '../../../App'

function Categories() {
  const [categories, setCategories] = useState([]);
  const [search, debouncedSearch, setSearch] = useDebounce('', 2000);
  const [currentCategory, setCurrentCategory] = useState(undefined);
  const AppContext = useContext(appContext)
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

  const loadCategories = () => {
    let params = []
    if (search.length > 0) params.push({ key: "search", value: search });
    AppContext.api.getRecords("categories", params).then((res) => {
      setCategories(res.data.records);
    });
  }

  useEffect(() => {
    loadCategories()
  }, [debouncedSearch]);

  const endContent = (
    <React.Fragment>
      <Button label="Ajouter" icon="pi pi-check" severity="success" onClick={() => addCategory()}></Button>
    </React.Fragment>
  );

  const addCategory = () => {
    let cat = {
      name:'',
      color:'#ffffff'
    }
    setCurrentCategory(cat)
  }

  const actionBtns = (category) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            icon="pi pi-pencil"
            onClick={() => setCurrentCategory(category)}
            text raised
          ></Button>
          <Button
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveCategory(event, category)}
            severity="danger"
            text raised
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const saveCategory = (category) => {
    if(Object.hasOwn(category, 'id')){
      AppContext.api.putRecords("categories", [category.id], category).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Catégorie modifiée', life: 3000 });
        loadCategories()
      })
    }
    else{
      AppContext.api.postRecords("categories", category).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Catégorie ajoutée', life: 3000 });
        loadCategories()
      })
    }
  }

  const cancelCategory = () => {
    setCurrentCategory(undefined)
  }

  const colorTemplate = (category) => {
    return <span style={{ backgroundColor: category.color, width: '50px', height: '30px', display: 'block' }}></span>
  }

  const confirmRemoveCategory = (event, category) => {

    const accept = () => {
      AppContext.api.deleteRecords("categories", [category.id]).then((res) => {
        loadCategories()
      });
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer cette catégorie?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  return (
    <React.Fragment>
      <ConfirmPopup />
      <CategoryDialog category={currentCategory} onSave={saveCategory} onCancel={cancelCategory} />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={<h2 className="m-0">Catégories</h2>}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <DataTable
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={categories}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          scrollHeight="flex"
          className="fullHeightTable"
          sortMode="multiple"
          removableSort
          globalFilterFields={['name']}
          filters={filters}
        >
          <Column field="name" sortable filter header="Nom"></Column>
          <Column header="Couleur" style={{ width: '85px' }} body={colorTemplate}></Column>
          <Column header="" style={{ width: '85px' }} body={actionBtns}></Column>
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default Categories;
