import React, { useState, useEffect, useContext } from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { appContext } from '../../../App'
import { getPathByName } from "../../../routesUtils"

function Forms(props) {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [search, debouncedSearch, setSearch] = useDebounce('', 2000);
  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)
  
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
});

  const loadForms = () => {
    if (props.type == 'template') {
      let params = [
        { key: 'include', value: 'forms_config.id,forms_config.name,forms_config.enabled' }
      ]
      if (search.length > 0) params.push({ key: "search", value: search });
      AppContext.api.getRecords("forms_config", params).then((res) => {
        setForms(res.data.records);
      });
    }
    else {
      let params = [
        { key: 'include', value: 'forms.id,forms.name,forms.enabled' }
      ]
      if (search.length > 0) params.push({ key: "search", value: search });
      AppContext.api.getRecords("forms", params).then((res) => {
        setForms(res.data.records);
      });
    }
  }

  useEffect(() => {
    loadForms()
  }, [debouncedSearch]);

  const startContent = (
    <React.Fragment>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={search} placeholder="Rechercher" onChange={(e) => setSearch(e.target.value)} />
      </span>
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <span className="p-buttonset">
        <Button label="Ajouter" icon="pi pi-check" severity="success"
          onClick={() => props.type == 'template' ? 
            navigate(getPathByName('configCreateForm')) : 
            navigate(getPathByName('portalCreateForm', {portalId:portalId}))}></Button>
      </span>
    </React.Fragment>
  );

  const actionBtns = (item) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            icon="pi pi-pencil"
            onClick={() => props.type == 'template' ? 
            navigate(getPathByName('configEditForm',{formId:item.id})) : 
            navigate(getPathByName('portalEditForm', {portalId:portalId,formId:item.id}))}
            text raised
          ></Button>
          <Button
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveForm(event, item)}
            severity="danger"
            text raised
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const confirmRemoveForm = (event, form) => {

    const accept = () => {
      AppContext.api.deleteRecords("forms", [form.id]).then((res) => {
        loadForms()
      });
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer ce formulaire?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  const enabledBody = (field) => {
    return field.enabled ? <strong>Activé</strong> : <span>Désactivé</span>
  };

  const enabledRowFilterTemplate = (options) => {
    return <Dropdown 
    value={options.value} 
    options={[{value:true,label:'Activé'},{value:false,label:'Désactivé'}]} 
    onChange={(e) => 
    options.filterApplyCallback(e.value)} 
    placeholder="Statut" 
    className="p-column-filter" 
    showClear 
    style={{ minWidth: '12rem' }} />
};

  return (
    <React.Fragment>
      <ConfirmPopup />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <DataTable
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={forms}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          scrollHeight="flex"
          className="fullHeightTable"
          globalFilterFields={['name', 'enabled']}
          filters={filters}
          sortMode="multiple"
          removableSort
        >
          <Column field="name" header="Nom" filter sortable></Column>
          <Column field="enabled" header="Statut" body={enabledBody} 
          dataType="boolean"
          filter
          filterElement={enabledRowFilterTemplate}
          sortable></Column>
          <Column header="" style={{ width: '85px' }} body={actionBtns}></Column>
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default Forms;
