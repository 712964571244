import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { appContext } from '../../../App'
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function LinkDialog(props) {
    const [currentLink, setCurrentLink] = useState(undefined);
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext)
    const [forms, setForms] = useState([])

    const loadForms = async () => {


        let groupedForms = [];

        if (props.type == 'template') {
            let params = [
                { key: "include", value: "forms_config.id,forms_config.name" }
            ]
            const res = await AppContext.api.getRecords("forms_config", params)
            groupedForms = res.data.records
        }
        else {
            let params = [
                { key: "include", value: "forms.id,forms.name" }
            ]
            const resG = await AppContext.api.get("plans/portal/forms")
            const resL = await AppContext.api.getRecords("forms", params)

            groupedForms = [
                {
                    name: 'Formulaires du portail',
                    items: resL.data.records
                },
                {
                    name: 'Formulaires communs',
                    items: resG.data
                }
            ];
        }

        setForms(groupedForms)
    }

    useEffect(() => {
        if (props.currentLink) {
            loadForms()
            setCurrentLink(props.currentLink)
            setVisible(true)
        }
    }, [props.currentLink]);

    const closeDialog = () => {
        props.onClose()
        setCurrentLink(undefined)
        setVisible(false)
    }

    const saveLink = () => {
        let errors = {}
        if (currentLink.title == '') errors['title'] = 'Champs "Titre" requis.'
        if (currentLink.type == '') errors['type'] = 'Champs "Type de lien" requis.'

        if (Object.keys(errors).length > 0) {
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({
                severity: 'error',
                summary: 'Erreurs',
                detail: msg.map((m, i) => <div key={i}>{m}</div>),
                life: 3000
            });
            return;
        }

        props.onChange(currentLink)
        setCurrentLink(undefined)
        setVisible(false)
    }

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
            <Button label="Modifier" icon="pi pi-pencil" onClick={saveLink} />
        </div>
    );


    const startContent = (
        <React.Fragment>
            Modification du lien
        </React.Fragment>
    );

    const setProperty = (property, value) => {
        setCurrentLink({ ...currentLink, [property]: value });
    }

    const linkTypes = [
        { id: 'link', name: 'Lien standard' },
        { id: 'access', name: 'FormAccess' },
        { id: 'formsolution', name: 'FormSolution' }
    ]

    const groupedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    const setFSFieldProperty = (option, value, property) => {
        var array = [...currentLink.formsolution_fields];
        var index = array.indexOf(option)
        if (index !== -1) {
            array[index][property] = value
            setCurrentLink({ ...currentLink, formsolution_fields: array });
        }
    }

    const FSNameBody = (option) => {
        return (
            <InputText value={option.name}
                onChange={(e) => setFSFieldProperty(option, e.target.value, 'name')}
                placeholder="Nom" className="w-full" />
        );
    };

    const FSNameValue = (option) => {
        return (
            <InputText value={option.value}
                onChange={(e) => setFSFieldProperty(option, e.target.value, 'value')}
                placeholder="Valeur" className="w-full" />
        );
    };

    const removeFSField = (event, field) => {
        var array = [...currentLink.formsolution_fields];
        var index = array.indexOf(field)
        if (index !== -1) {
            array.splice(index, 1);
            setCurrentLink({ ...currentLink, formsolution_fields: array });
        }
    }

    const actionFSBtns = (field) => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button
                        text raised
                        icon="pi pi-trash"
                        onClick={(event) => removeFSField(event, field)}
                        size="small"
                        severity="danger"
                    ></Button>
                </span>
            </React.Fragment>
        );
    };

    const addFSField = () => {
        let field = {
            name: '',
            value: ''
        }

        if (!currentLink?.formsolution_fields) {
            var array = [field];
            setCurrentLink({ ...currentLink, formsolution_fields: array });
        }
        else {
            var array = [...currentLink.formsolution_fields]
            array.push(field)
            setCurrentLink({ ...currentLink, formsolution_fields: array });
        }
    }

    return (
        <React.Fragment>
            {currentLink && <Dialog
                header={startContent}
                footer={footerContent}
                visible={isVisible} style={{ width: '600px' }} height="90vh" onHide={closeDialog}>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkTitle">Titre</label>
                    <InputText id="linkTitle" value={currentLink.title}
                        onChange={(e) => setProperty('title', e.target.value)}
                        placeholder="Titre" className="mr-2" />
                </div>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkDescription">Description</label>
                    <InputText id="linkDescription" value={currentLink.description}
                        onChange={(e) => setProperty('description', e.target.value)}
                        placeholder="Description" className="mr-2" />
                </div>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkType">Type de lien</label>
                    <Dropdown id="linkType" value={currentLink.type}
                        onChange={(e) => setProperty('type', e.value)}
                        options={linkTypes}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Selectionner un type de lien" />
                </div>
                {currentLink.type == 'link' && <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkUrl">URL</label>
                    <InputText id="linkUrl" value={currentLink.link_url}
                        onChange={(e) => setProperty('link_url', e.target.value)}
                        placeholder="https://..." className="mr-2" />
                </div>}
                {currentLink.type == 'access' && <><div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="linkForm">Formulaire de contact</label>
                    <Dropdown id="linkForm" value={parseInt(currentLink.form_id)}
                        onChange={(e) => setProperty('form_id', e.value)}
                        options={forms}
                        optionLabel="name"
                        optionValue="id"
                        optionGroupLabel={props.type == 'template' ? null : "name"}
                        optionGroupChildren={props.type == 'template' ? null : "items"}
                        optionGroupTemplate={props.type == 'template' ? null : groupedItemTemplate}
                        filter
                        placeholder="Selectionner un formulaire" />
                </div>
                    <div className="flex flex-column mb-3 gap-3">
                        <label htmlFor="linkWebhookEmail">Email</label>
                        <InputText id="linkWebhookEmail" value={currentLink.webhook_email}
                            onChange={(e) => setProperty('webhook_email', e.target.value)}
                            placeholder="xxxx@xxxx.com" className="mr-2" />
                    </div>
                    <div className="flex flex-column mb-3 gap-3">
                        <label htmlFor="linkWebhookUrl">URL</label>
                        <InputText id="linkWebhookUrl" value={currentLink.webhook_url}
                            onChange={(e) => setProperty('webhook_url', e.target.value)}
                            placeholder="https://..." className="mr-2" />
                    </div></>}

                {currentLink.type == 'formsolution' && <>
                    <div className="flex flex-column mb-3 gap-3">
                        <label htmlFor="linkFSCode">Code FormSolution</label>
                        <InputText id="linkFSCode" value={currentLink.formsolution_code}
                            onChange={(e) => setProperty('formsolution_code', e.target.value)}
                            className="mr-2" />
                    </div>
                    <div class="text-right mb-1 mt-2">
                        <Button
                            text raised
                            icon="pi pi-plus"
                            onClick={(event) => addFSField()}
                            size="small"
                            label="Ajouter un champ"
                        ></Button>
                    </div>
                    {currentLink?.formsolution_fields &&
                        <DataTable
                            value={currentLink.formsolution_fields}>
                            <Column key={"name"} columnKey={"name"} header={"Nom"} body={FSNameBody} />
                            <Column key={"value"} columnKey={"value"} header={"Valeur"} body={FSNameValue} />
                            <Column header="" body={actionFSBtns} style={{ width: '45px' }}></Column>
                        </DataTable>}</>}

            </Dialog>}
        </React.Fragment>
    );
}

export default LinkDialog;
