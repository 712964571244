import React, { useState, useEffect, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { useRouteLoaderData } from "react-router-dom";
import { appContext } from '../../../App'

function UserProfile(props) {
    const [user, setUser] = useState([]);
    const AppContext = useContext(appContext)
    const my = useRouteLoaderData('root');

    useEffect(() => {
        setUser(my.user);
    }, []);

    const setProperty = (property, value) => {
        setUser({ ...user, [property]: value });
    }

    const endContent = (
        <React.Fragment>
            <span className="p-buttonset">
                <Button label="Sauvegarder" icon="pi pi-check" severity="success" onClick={() => saveUser()}></Button>
            </span>
        </React.Fragment>
    );


    const saveUser = () => {
        let errors = {}
        if (user.name == '') errors['name'] = 'Champs "Nom" requis.'

        if (Object.keys(errors).length > 0) {
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({
                severity: 'error',
                summary: 'Erreurs',
                detail: msg.map((m, i) => <div key={i}>{m}</div>),
                life: 3000
            });
            return;
        }

        let userTemp = { ...user }
        let userData = {
            name: userTemp.name
        }
        if (userTemp.password1 != '') {
            if (userTemp.password1 != userTemp.password2) {
                AppContext.showNotification({ severity: 'error', summary: 'Erreur', detail: 'Le mot de passe et la vérification ne correspondent pas.', life: 3000 });
                return;
            }
            else {
                userData.password = userTemp.password1;
            }
        }

        AppContext.api.put("user/me", userData).then((res) => {
            AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Profile sauvegardé', life: 3000 });
        })
    }

    return (
        <div className="flex flex-column">
            <div className="flex flex-grow-1">
                {user && <Card
                    header={
                        <Toolbar
                            start={<h2 className="m-0">{user.email}</h2>}
                            end={endContent}
                            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
                        />
                    }
                    className="flex-grow-1 shadow-none border-1 surface-border mr-2 fullHeightCard overflowYContentCard"
                >

                    <div className="flex flex-column mb-3 gap-3 mt-3">
                        <label htmlFor="name">Nom</label>
                        <InputText
                            id="name"
                            value={user.name}
                            onChange={(e) => setProperty('name', e.target.value)}
                            placeholder="Nom" />
                    </div>
                    <div className="flex flex-column mb-3 gap-3 mt-3">
                        <label htmlFor="password1">Mot de passe</label>
                        <div>
                            <Password id="password1"
                                toggleMask
                                value={user.password1}
                                onChange={(e) => setProperty('password1', e.target.value)}
                                feedback={true} inputStyle={{ width: '100%' }} className="w-full" />
                        </div>
                    </div>
                    <div className="flex flex-column mb-3 gap-3 mt-3">
                        <label htmlFor="password1">Confirmation du mot de passe</label>
                        <div><Password id="password2"
                            toggleMask
                            value={user.password2}
                            onChange={(e) => setProperty('password2', e.target.value)}
                            feedback={false} inputStyle={{ width: '100%' }} className="w-full" />
                        </div>
                    </div>
                </Card>}
            </div>
        </div>
    );
}

export default UserProfile;
